<span mat-dialog-actions align="end" class="close-icon-mat-box" style="z-index: 4000;padding-bottom: 5px;display: flex;justify-content: flex-end;" (click)="closeModal()">
    <img src="../../../../assets/close-icon.png" width="15" alt="">
</span>
<div class="max-attempt-container">
    <!-- <div>
        <button type="button" class="btn close-btn" (click)="closeModal()" aria-label="Close">
            <img src="../../../../assets/icons/practice/close-btn.svg" />
        </button>
    </div> -->
    <div class="header">
        <span class="header-content">Do you want to start this topic 
            from the beginning?</span>
    </div>
    
    <div mat-dialog-content>
        <div class="insights-data">
            <span>Resetting will result in the loss of previous performance data.</span>
        </div>

        
    </div>
    <div class="footer" style="margin-top: 10px;">
            
        <div class="col-12 ps-lg-1" style="display: flex;justify-content: center;">
            <button type="button" class="btn fill-btn" (click)="handleOk()" style="width: 90%;">YES, RESET THIS TOPIC</button>

        </div>
    </div>
</div>
