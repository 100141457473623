import { Action } from '@ngrx/store';
import { MockTest } from 'src/app/shared/components/models/mockTestList';

export class modelViewActions {
    static SET_MOCKTEST = 'SET_MOCKTEST';
    // static UPDATE_USERPROFILE_SUCCESS = 'GET_USERPROFILE_SUCCESS';
    // static CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

    setMockTest(mockTest: MockTest[]) {
        return { type: modelViewActions.SET_MOCKTEST, payload: mockTest };
    }


}
