import { Component,OnChanges,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-exam-instruction-modal',
  templateUrl: './exam-instruction-modal.component.html',
  styleUrls: ['./exam-instruction-modal.component.scss']
})
export class ExamInstructionModalComponent implements OnChanges {
  @Input() instructionData:any
  @Input() modalReference:any
  
  display:any="block"
constructor(public activeModal: NgbActiveModal){
  
}

ngOnChanges(){

}

closeModal(){
  // this.display="none";
  this.modalReference.close()
}


}
