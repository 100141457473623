import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
// import { ProductDummyService } from './services/product-dummy.service';
import { EffectsModule } from '@ngrx/effects';
import { AuthActions } from '../auth/actions/auth.actions';
import { AuthenticationEffects } from '../auth/effects/auth.effects';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CanActivateViaAuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
// Components
// Services
// import { AuthService } from './services/auth.service';
import { ExamService } from './services/exam.service';
import { VariantParserService } from './services/variant-parser.service';
import { VariantRetriverService } from './services/variant-retriver.service';
import { ModelTestPaperService } from './services/model-test-paper.service';
import { modelViewActions } from 'src/app/modules/feature/assessment-player/model-test/actions/modelview.actions';
import { CommonConfigService } from './services/commonConfig.service';
import { CheckSameDirective } from './validatores/checkSame.directive';
import { UsernameValidator } from './validatores/usernameValidate.directive';
import { PasswordValidator } from './validatores/passwordValidate.directive';
import { EmailValidator } from './validatores/emailValidate.directive';
import { DOBValidator } from './validatores/dobValidate.directive';
import { CompareDirective } from './validatores/compare.directive';
import { CallbackPipe } from './pipes/callback.pipe';
import { SharedService } from './services/shared.service';
import { ProfileImage } from './models/profileImg';
// import { NgProgressModule } from 'ngx-progressbar';
// import { NgProgressHttpModule } from '@ngx-progressbar/http';

@NgModule({
  declarations: [
    CompareDirective,
    CheckSameDirective,
    UsernameValidator,
    PasswordValidator,
    EmailValidator,
    DOBValidator,
    CallbackPipe
  ],
  exports: [
    // components
    // DummyService
    // NgProgressModule,
    CompareDirective,
    CheckSameDirective,
    UsernameValidator,
    PasswordValidator,
    EmailValidator,
    DOBValidator,
    CallbackPipe
  ],
  imports: [
    // Were not working on modules sice update to rc-5
    // TO BE moved to respective modules.
    /*     EffectsModule.forFeature([
          AuthenticationEffects,
        ]), */
    HttpClientModule,
    // NgProgressModule,
    // NgProgressHttpModule,
    NgxSpinnerModule
  ],
  providers: [
    VariantParserService,
    VariantRetriverService,
    // AuthService,
    // ProductDummyService,
    ExamService,
    ModelTestPaperService,
    SharedService,
    AuthActions,
    CommonConfigService,
    modelViewActions,
    ProfileImage,
    /* CanActivateViaAuthGuard, */
    /* { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true } */
  ]
})
export class CoreModule { }
