import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {



    transform(totalSeconds: number): string {
        var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
        var seconds = totalSeconds - (hours * 3600) - (minutes * 60);
        seconds = Math.round(seconds * 100) / 100;
        if (hours <= 0) {
            if (minutes < 1) {
                return '00:' + this.convertToTwoDigit(seconds);
            }
            else {
                return '00:' + this.convertToTwoDigit(minutes) + ':' + this.convertToTwoDigit(seconds)
            }
        }
        else {
            return this.convertToTwoDigit(hours) + ':' + this.convertToTwoDigit(minutes) + ':' + this.convertToTwoDigit(seconds);
        }
    }

    convertToTwoDigit(value) {
        return ("0" + value).slice(-2);
    }

}
