import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss']
})
export class ConfirmResetComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
      // this.maxCount = data.maxCount
    }
  closeModal(){
    this.dialogRef.close()
  }
  handleOk(){
    this.dialogRef.close(true)
  }
}
