import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';


@Directive({
    selector: '[appEmailVal]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }]
})

export class EmailValidator implements Validator {

    //   @Input('appCompare') appCompare: AbstractControl;
    constructor() { }

    validate(control: AbstractControl): { [key: string]: any } | null {

        const validated: any = {};

        // const regCom1 = new RegExp('\\.com$');
        const regCom = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!String(control.value).match(regCom)) {
            validated['com'] = '*Email is invalid';
        }
        // if(!String(control.value).match(regCom1)){
        //     validated['com1'] = '*Email has to contain .com';
        // }

        console.log(validated);
        return validated;
    }
}
