import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appCompare]',
  providers: [{provide: NG_VALIDATORS, useExisting: CompareDirective, multi: true}]
})
export class CompareDirective implements Validator {

  @Input('appCompare') appCompare!: AbstractControl;
  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.appCompare.value === control.value ? null : {compare: true};
  }
}
