<span mat-dialog-actions align="end" style="z-index: 4000;padding-bottom: 5px;display: flex;justify-content: flex-end;" (click)="onClose()">
    <img src="../../../../assets/close-icon.png" alt="">
</span>
<div class="max-attempt-container">
    <!-- <div>
        <button type="button" class="btn close-btn" (click)="onClose()" aria-label="Close">
            <img src="../../../../assets/icons/practice/close-btn.svg" />
        </button>
    </div> -->
    <!-- <div class="header">
        <span class="header-content">{{maxCount}}/{{maxCount}} questions attempted</span>
    </div> -->
    
    <div mat-dialog-content>
        <div class="insights-data">
            <img style="margin-bottom: 10px;" src="../../../../assets/fi_9750864.svg" alt="">
            <h3 style="font-weight: bold;font-size: 20px;">Question has been reported !</h3>
            <span style="font-weight: bold;font-size: 14px;">We appreciate your effort.</span>    
        </div>

        
    </div>
     
</div>
