import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MobilePaymentRoutingModule } from './mobile-payment-routing.module';
import { MobilePaymentComponent } from './mobile-payment.component';
import { CcavenueFormComponent } from './ccavenue-form/ccavenue-form.component';


@NgModule({
  declarations: [
    MobilePaymentComponent,
    CcavenueFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MobilePaymentRoutingModule
  ]
})
export class MobilePaymentModule { }
