import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbCarouselModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CarouselModule as OwlCarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarouselModule } from 'primeng/carousel';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { AccordianComponent } from './accordian/accordian.component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { ExamInstructionModalComponent } from './exam-instruction-modal/exam-instruction-modal.component';
import { GlobalSpinnerComponent } from "./global-spinner/global-spinner.component";
import { GlobalSpinnerInterceptor } from './global-spinner/global-spinner.interceptor';
import { LmsStoryComponent } from './lms-story/lms-story.component';
import { MaxAttemptDialogComponent } from './max-attempt-dialog/max-attempt-dialog.component';
import { MockMeterComponent } from './mock-meter/mock-meter.component';
import { PreLoginProductsComponent } from './pre-login-products/pre-login-products.component';
import { RedeemBottomSheetComponent } from './redeem-bottom-sheet/redeem-bottom-sheet.component';
import { RedeemModalComponent } from './redeem-modal/redeem-modal.component';
import { ReferEarnBottomSheetComponent } from './refer-earn-bottom-sheet/refer-earn-bottom-sheet.component';
import { ReferEarnDialogComponent } from './refer-earn-dialog/refer-earn-dialog.component';
import { ReportSuccessMsgComponent } from './report-success-msg/report-success-msg.component';
import { SemiCircleRangeSliderComponent } from './semi-circle-range-slider/semi-circle-range-slider.component';
import { StoryCarouselComponent } from './story-carousel/story-carousel.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { ToastsContainerComponent } from "./toasts-container/toasts-container.component";
import { VerticalProgressStepsComponent } from './vertical-progress-steps/vertical-progress-steps.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';


@NgModule({
  declarations: [
    SemiCircleRangeSliderComponent,
    StoryCarouselComponent,
    VerticalProgressStepsComponent,
    GlobalSpinnerComponent,
    LmsStoryComponent,
    RedeemModalComponent,
    ToastsContainerComponent,
    ExamInstructionModalComponent,
    AccordianComponent,
    TermAndConditionComponent,
    SafePipe,
    ReferEarnDialogComponent,
    PreLoginProductsComponent,
    MaxAttemptDialogComponent,
    ConfirmResetComponent,
    ReportSuccessMsgComponent,
    RedeemBottomSheetComponent,
    ReferEarnBottomSheetComponent,
    MockMeterComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    HttpClientModule,
    NgbModule,
    NgbCarouselModule,
    NgbToastModule,
    CarouselModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    NgxSpinnerModule,
    NgbAccordionModule,
    OwlCarouselModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    CdkAccordionModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    NgbModule,
    NgbCarouselModule,
    NgbToastModule,
    CarouselModule,
    MatSelectModule,
    SemiCircleRangeSliderComponent,
    StoryCarouselComponent,
    VerticalProgressStepsComponent,
    GlobalSpinnerComponent,
    LmsStoryComponent,
    RedeemModalComponent,
    NgxSpinnerModule,
    ToastsContainerComponent,
    ExamInstructionModalComponent,
    AccordianComponent,
    TermAndConditionComponent,
    NgbAccordionModule,
    OwlCarouselModule,
    NgCircleProgressModule,
    ReferEarnDialogComponent,
    PreLoginProductsComponent,
    MaxAttemptDialogComponent,
    MatSlideToggleModule,
    MatTabsModule,
    MockMeterComponent,
    CdkAccordionModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalSpinnerInterceptor, multi: true }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
