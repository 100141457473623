import { Injectable } from '@angular/core';
import {
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, of, timeout } from 'rxjs';
import { GlobalSpinnerService } from './global-spinner.service';
import { SPINNER_NOT_REQUIRED_URLS } from './global-spinner.constant';

@Injectable()
export class GlobalSpinnerInterceptor implements HttpInterceptor {
    readonly SPINNER_NOT_REQUIRED_URLS = SPINNER_NOT_REQUIRED_URLS
    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: GlobalSpinnerService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(SPINNER_NOT_REQUIRED_URLS.some(val => req.url.includes(val))) {
            return next.handle(req) 
        }
        this.requests.push(req);

        this.loaderService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(req)
                /* .pipe(
                    timeout(2000),
                    catchError(e => {
                        // do something on a timeout
                        console.log("Timeout error interceptor for ", e);
                        
                        return of(null);
                      })) */
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        console.warn('error' + err);
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
        
    }
}
