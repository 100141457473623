import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { goal, goalSelectedByStudent } from './model/goalModel';
import { PageRequest } from './model/data.table.model';
import { IPageRequestPractice } from './model/page.request.model';
import { ICustomLoginRequest } from './model/custom.login.request';
import { ICustomLoginSuccessResponse } from './model/custom.login.success.response';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {

    constructor(private http: HttpClient) { }

    requestLogin(payload: ICustomLoginRequest): Observable<ICustomLoginSuccessResponse> {
        return this.http.post<any>(
            `${environment.studentService}/v1/auth/login`, payload);
    }

    requestLogout(payload: {refreshToken:string}): Observable<void> {
        return this.http.post<any>(
            `${environment.studentService}/v1/auth/logout`, payload);
    }

    sendVerificationCode(phoneNumber:string): Observable<{expires_in:number}> {
        return this.http.get<any>(
            `${environment.studentService}/v1/auth/sms/verification-code?phoneNumber=${phoneNumber}`);
    }

    refreshToken(payload: {refreshToken:string}): Observable<ICustomLoginSuccessResponse> {
        return this.http.put<any>(
            `${environment.studentService}/v1/auth/refresh_token`, payload);
    }
}