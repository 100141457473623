import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-payment',
  templateUrl: './mobile-payment.component.html',
  styleUrls: ['./mobile-payment.component.scss']
})
export class MobilePaymentComponent {

}
