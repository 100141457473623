import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import '@gugadev/wc-stories';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lms-story',
  templateUrl: './lms-story.component.html',
  styleUrls: ['./lms-story.component.scss']
})
export class LmsStoryComponent implements AfterViewInit {
  @ViewChild('lmsStory') lmsStory!: ElementRef<HTMLElement>;
  @ViewChild('storyImage') storyImage!: ElementRef<HTMLElement>;
  @ViewChild('leftBtn') leftBtn!: ElementRef<HTMLElement>;

  width = 320;
  height = 480;
  @Input() imageData;
  @Input() stories;

  storyTitle: string = "";
  storyDesription: string = "";

  cref: any = { startAt: 0 };

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngAfterViewInit(): void {
    // To show initial image.
    let el: any = this.lmsStory.nativeElement;

    el.goPrevious();

    this.cref = el;

    setTimeout(() => {

      this.leftBtn.nativeElement.click();
    }, 1000);

  }

  prevStory() {
    // let el: any = this.lmsStory.nativeElement;
    this.cref.goPrevious();
  }

  nextStory() {
    // let el: any = this.lmsStory.nativeElement;
    this.cref.goNext();
  }


}
