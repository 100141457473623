import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppState } from '../../services/model/interfaces';
import { modelViewActions } from 'src/app/modules/feature/assessment-player/model-test/actions/modelview.actions';
import { MockTest } from '../../shared/components/models/mockTestList';
@Injectable({
  providedIn: 'root'
})
export class ModelTestPaperService {
  constructor(private http: HttpClient,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private actions: modelViewActions,
    ) { }

  getmockTestList():Observable<MockTest[]>{
    let url:string = `${environment.apiEndpoint}/v1/mocks`;
    return this.http.get<MockTest[]>(url)
    .pipe(map((res: any) => {
      
      res.sort(function(a: any, b: any){
        var nameA=a.folderName.toLowerCase(), nameB=b.folderName.toLowerCase();
        if (nameA < nameB) //sort string ascending
         return -1;
        if (nameA > nameB)
         return 1;
        return 0; //default return value (no sorting)
       });
      return res;
    }))

  }

  getPdfSrc(){
    
  }


  
}
