import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-refer-earn-bottom-sheet',
  templateUrl: './refer-earn-bottom-sheet.component.html',
  styleUrls: ['./refer-earn-bottom-sheet.component.scss']
})
export class ReferEarnBottomSheetComponent {
  isCodeCopied: boolean = false; 
  dynamicData: any = {};
  constructor(private clipboard: Clipboard,private studentService:StudentService,private _bottomSheet: MatBottomSheet){

  }

  ngOnInit(){
    this.studentService.getReferralDetails().subscribe(data => {
      this.dynamicData = data;
      console.log('Fetched data:', data);
      console.log('Referral code:', this.dynamicData.referralCode);
    });
  }

  copyToClipboard() {
    const referralCode = this.dynamicData.referralCode;

    if (this.clipboard.copy(referralCode)) {
      this.isCodeCopied = true;
      setTimeout(() => {
        this.isCodeCopied = false;
      }, 4000);
    }
  }

  
  onBottomSheetclose() {
    this._bottomSheet.dismiss();
  }
 
}
