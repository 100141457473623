import { Component, OnInit, Input } from '@angular/core';
import { ImageCard } from '../models/imageCard';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {

  @Input("cardbody")
  imageCard!:ImageCard


  constructor() { }

  ngOnInit() {
  }

}
