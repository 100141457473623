import { Component, OnInit, Input } from '@angular/core';
import { ReviewCard } from '../models/reviewCard';

@Component({
  selector: 'app-reviewcard',
  templateUrl: './reviewcard.component.html',
  styleUrls: ['./reviewcard.component.scss']
})
export class ReviewcardComponent implements OnInit {

  @Input('reviewcard')
  reviewCard!:ReviewCard
  
  constructor() { }

  ngOnInit() {
  }

}
