import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.scss']
})
export class TermAndConditionComponent {
  @Input() termsAndPrivacyData: any[] = []
}
