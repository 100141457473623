import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { StudentService } from 'src/app/services/student.service';
import { PracticePaymentModalComponent } from '../../feature/post-login/practice/practice-payment-modal/practice-payment-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-redeem-modal',
  templateUrl: './redeem-modal.component.html',
  styleUrls: ['./redeem-modal.component.scss']
})
export class RedeemModalComponent implements OnDestroy {
  // @Input() modalReference:any
  private destroyed$ = new Subject<void>();
  coinCount:any=0;
  practiceStatus:boolean=false;
  scholarshipPercentage:number = 0;
  scholarshipExpired:string = "";

constructor(private studentService:StudentService,private router:Router,
  private modalService: NgbModal,
  public dialogRef: MatDialogRef<RedeemModalComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,) {
  this.getStudentCoinDta();
  this.getMyProducts();
}
ngOnInit() {
  this.scholarshipPercentage = this.data?.scholarshipPercentage || 0;
  this.scholarshipExpired = this.data?.scholarshipExpired || "";
  //console.warn("this.scholarshipPercentage ",this.scholarshipPercentage);
}

  getStudentCoinDta() {
    this.studentService.getStudentCoins().pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.coinCount = res.currentBalance
    })
}

getMyProducts(){
  this.studentService.getProducts().pipe(takeUntil(this.destroyed$)).subscribe(res => {
    let arr: any[] = res.filter((r: any) => r.prop.product.keyFeature === "PRACTICE");
    if (arr.length != 0) {
        this.practiceStatus = true;
    } else {
        this.practiceStatus = false;
    }
  })
}

ngOnDestroy(): void {
  this.destroyed$.next();
  this.destroyed$.complete();
}

navigateToPractice(){
  this.dialogRef.close({ action: false });
  const modalRef = this.modalService.open(PracticePaymentModalComponent, {
    size: 'lg',
    centered: true,
  });
  modalRef.componentInstance.modalReference = modalRef;
  // this.router.navigate(['post-login/practice']);
  
}
navigateToTests(){
  this.router.navigate(['post-login/assessment']);
  this.dialogRef.close({ action: false });

}

close() {
  this.dialogRef.close({ action: false });
}

}
