import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmResetComponent } from '../confirm-reset/confirm-reset.component';

@Component({
  selector: 'app-vertical-progress-steps',
  templateUrl: './vertical-progress-steps.component.html',
  styleUrls: ['./vertical-progress-steps.component.scss'],
})
export class VerticalProgressStepsComponent implements OnChanges {
  @Input() isVisibleResetBtn: boolean = false;
  @Input() steps: any[] = [];
  @Input() isChecked: boolean = true;
  @Output() resetAction = new EventEmitter();
  @Output() selectTopics = new EventEmitter();

  stepList: any[] = [];
  selectedTopics: string[] = [];
  showResetBtn:boolean = false
  constructor(private dialog: MatDialog) { }
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['steps'] && changes['steps'].currentValue) || this.isChecked) {

      this.stepList = this.steps.map((s: any) => ({ ...s, checked: true }));
      // console.log(this.stepList)
    } else if (!this.isChecked) {

      this.stepList = this.steps.map((s: any) => ({ ...s, checked: false }));
    }
    this.stepList.forEach(step=>{
      if(step.completed){
        this.showResetBtn = true;
      }
    })
    this.selectTopics.emit(this.stepList[0])
  }

  onTopicToggle(step: any, idx: number) {
    step.checked = !step.checked;

    // if (idx === 0) {
    //     step.checked = !step.checked;
    //     const topicIdx = this.selectedTopics.indexOf(step.topicId);
    //     if (topicIdx === -1) {
    //         this.selectedTopics.push(step.topicId);
    //     } else {
    //         this.selectedTopics.splice(idx, 1);
    //     }
    // }
    this.selectTopics.emit(step)
    // localStorage.setItem('selectedTopics', JSON.stringify(this.selectedTopics));
  }
  onReset(ev: any) {
    ev.stopPropagation();
    let dialogRef = this.dialog.open(ConfirmResetComponent, {
      minWidth: '370px',
      width: '420px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        let arr: any[] = [];
        // this.stepList[0].subTopics.forEach((el) => {
        //   arr.push(el.subTopicId);
        // });
        arr = [this.stepList[0].topicId]
        if (arr.length) {
          this.resetAction.emit(arr);
        }
      }

      // console.log(resp)
    });
  }
}
