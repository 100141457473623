
<div class="instruction-content">
    <div>
        <button type="button" class="btn close-btn" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
            <img src="../../../../assets/icons/practice/close-btn.svg" />
        </button>
    </div>
   
    <div [innerHTML]="instructionData">
       
    </div>
</div>



<!-- <div class="modal goal-change-confirm-modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}"
    style="overflow-y: scroll; ">

    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
           
            <button type="button" class="btn close-btn" aria-label="Close" (click)="closeModal()">
                <img src="../../../../../../assets/icons/assessment/close-icon.svg" />
            </button>
            <div class="modal-body">
              
                    <div [innerHTML]="instructionData">
                       
                    </div>
                
            </div>
            
        </div>
    </div>
</div> -->
<!-- <div class="modal-backdrop" [ngStyle]="{'display':display, 'opacity': 0.9}"></div> -->
