export const SPINNER_NOT_REQUIRED_URLS = [
    'v1/activity',
    'v1/social-proof-text',
    'v1/students/auditrail',
    'v1/coin',
    'v1/streak',
    'v1/my-available-scholarship-test',
    'v1/testimonial',
    'v1/stories',
    'v1/auth/refresh_token'
]