import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { StudentService } from 'src/app/services/student.service';
import { PracticePaymentModalComponent } from '../../feature/post-login/practice/practice-payment-modal/practice-payment-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-redeem-bottom-sheet',
  templateUrl: './redeem-bottom-sheet.component.html',
  styleUrls: ['./redeem-bottom-sheet.component.scss']
})
export class RedeemBottomSheetComponent {
  private destroyed$ = new Subject<void>();
  subStatus: boolean = false;
  coinCount: number = 0;

  constructor(private studentService:StudentService, private modalService: NgbModal,private router:Router,private _bottomSheet: MatBottomSheet){
    
  }
  ngOnInit(){
    this.getStudentCoinDta();
    this.getMyProducts()
  }

  getMyProducts() {
    this.studentService.getProducts({ summary: true }).pipe(takeUntil(this.destroyed$)).subscribe(resp => {

        let arr: any[] = resp.filter((r: any) => r.productKeyFeature === "PRACTICE");

        if (arr.length != 0) {
            // this.selectedPractice = arr[0];
            this.subStatus = true;
        } else {
            this.subStatus = false;
            // this.getAllPracticeProducts();
        }

    })
}
getStudentCoinDta() {
  this.studentService.getStudentCoins().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.coinCount = res.currentBalance
  })
}

viewPlans(){
  this._bottomSheet.dismiss();
        //GTM
        // window["webengage"].user.setAttribute({});
        // window['dataLayer'] = window['dataLayer'] || [];
        // window['dataLayer'].push({
        //     'event': 'select_plan_button_clicked'
        // });
        console.log("Hello")
        const modalRef = this.modalService.open(PracticePaymentModalComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.modalReference = modalRef;
        console.log("Bye")
}

navigateToTests() {
  this.router.navigate(['post-login/assessment']);
  this._bottomSheet.dismiss();
  // this.dialogRef.close({ action: false });

}
onBottomSheetclose() {
  this._bottomSheet.dismiss();
}

ngOnDestroy(): void {
  this.destroyed$.next();
  this.destroyed$.complete();
}
}
