import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, interval, switchMap } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { StudentService } from './services/student.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'examFactorLMS';
  lastNavigation: NavigationEnd;

  isCollapsExploreCatalogue: boolean = false;
  isExpandedSidenav: boolean = false;
  pageName: string = 'Home';
  appLogin:number = 0;
  public activityId: string = "";

  previousNavigationUrl: string | null = null;
  lastApiCallTimestamp: number | null = null;

  constructor(private translate: TranslateService, private router: Router, 
    private studentService: StudentService, private authService: AuthService) {
    translate.setDefaultLang('en');
  }

  exploreCatalogue() {
    this.isCollapsExploreCatalogue = !this.isCollapsExploreCatalogue;
    console.log(this.isCollapsExploreCatalogue);
  }

  renderedPage(page: string) {
    this.pageName = page
  }

  ngOnInit() {
    let waiting = 10 * 60 * 1000;
    
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.router.events.pipe(filter(e => e instanceof NavigationEnd), switchMap((e) => {
      this.lastNavigation = e as NavigationEnd;
      this.notifyOnPage();
      return interval(waiting);
    }))
      .subscribe(r => {
        this.notifyOnPage();
      });

    //   this.router.events.subscribe((event: Event) => {
    //     if (event instanceof NavigationSta)rt) {
    //         // Show progress spinner or progress bar
    //         console.log('Route change detected');
    //     }

    //     if (event instanceof NavigationEnd) {
    //         // Hide progress spinner or progress bar
    //         console.log(event);
    //     }

    //     if (event instanceof NavigationError) {
    //          // Hide progress spinner or progress bar

    //         // Present error to user
    //         console.log(event.error);
    //     }
    // });
    // const deviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();
    // console.log("deviceInfo ",deviceInfo);
    // console.log("isMobile ",isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log("isTablet ",isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log("isDesktopDevice ",isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      console.warn('document.hidden');
      if (this.activityId !== null && this.activityId !== '') {
        this.studentService.updateActivity(this.activityId).subscribe(response => {
          console.warn('updateActivity called with id: ', this.activityId);
        });
        this.activityId = '';
      }
    } else {
      console.warn('document.active');
      //const userAgent = window.navigator.userAgent;
      //console.log(userAgent);
      this.lastApiCallTimestamp = Date.now();
      this.notifyOnPage();
    }
  };

  async notifyOnPage() {
    if (await this.authService.isLoggedIn()) {
      let user = await this.authService.userName();
      if (this.activityId == '' || this.lastNavigation?.url !== this.previousNavigationUrl) {
        this.postActivity(this.activityId, user, this.lastNavigation?.url);
      } else if (this.lastApiCallTimestamp && Date.now() - this.lastApiCallTimestamp > 5 * 60 * 1000) {
        console.warn('More than 5 minutes have passed. Calling the API again.');
        this.lastApiCallTimestamp = Date.now();
        this.postActivity(this.activityId, user, this.lastNavigation?.url);
      } else {
        console.warn('Navigation URL unchanged.');
      }
    }
  }

  private postActivity(id: string, studentId: string, pageUrl: string) {
    this.studentService.postActivity({id: id,studentId: studentId,pageUrl: pageUrl}).subscribe(act => {
      this.activityId = act.id;
      console.warn("activityId: ", this.activityId);
    });
  }
}