<span mat-dialog-actions align="end" class="crossAction" (click)="close()">
  <img src="./assets/close-icon.png" alt="cross" />
</span>
<div class="container-fluid voucher-dialog">
  <div class="edit-header" *ngIf="scholarshipPercentage==0">
    <img src="./assets/icons/header/coin.svg" alt="coin" />
    You have {{ coinCount }} coins to redeem
  </div>
  <div class="edit-header" *ngIf="scholarshipPercentage!=0">
    <img src="./assets/icons/header/coin.svg" alt="coin" />
    <span class="d-flex flex-column">
      <span>You have {{scholarshipPercentage}}% scholarship</span>
      <span class="expiry-date opacity-07 fst-italic">Expired on {{scholarshipExpired}}</span>
    </span>
    
    
  </div>
  
  <div class="sub-header">Buy Tests</div>
  <div class="plan-details">
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Realistic CUET Experience</span>
        <span
          >Build your skills with Mock tests designed as per the NTA
          standards.</span
        >
      </div>
    </div>
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Detailed Analysis</span>
        <span
          >Get detailed insights into your performance such as areas of
          strength, improvement, percentile and more</span
        >
      </div>
    </div>
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Live Mocks</span>
        <span
          >Live the CUET experience with Live Mocks that replicate exam
          conditions to get you ready for the Exam Day</span
        >
      </div>
    </div>
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Skill Development</span>
        <span
          >The mocks provide comprehensive topical coverage and help you
          progressively build your skills</span
        >
      </div>
    </div>
    <button
      mat-raised-button
      class="commonBtnStyle"
      (click)="navigateToTests()"
    >
      <span> Buy Now </span>
      <img src="./assets/icons/assessment/start-arrow-black.svg" />
    </button>
  </div>
</div>
