import { Component,OnInit,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ccavenue-form',
  templateUrl: './ccavenue-form.component.html',
  styleUrls: ['./ccavenue-form.component.scss']
})
export class CcavenueFormComponent implements OnInit {
  @ViewChild('myFormPost') myFormPost!: ElementRef;
  accessCode:string = "";
  encRequest:string = "";
  orderData:any;
  constructor(private route: ActivatedRoute){}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      console.log(params);
      this.orderData = params;
      this.accessCode = params.accessCode;
      this.encRequest = params.encRequest;
      console.log("accessCode: ",params.accessCode);
      console.log("encRequest: ",params.encRequest);
    });
    console.warn("Method called CCAvenue form");
    //this.onPost();
  }
  ngAfterViewInit() {
    this.onPost();
  }

  onPost() {
    this.myFormPost.nativeElement.submit();
  }



}
