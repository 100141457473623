import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { MasterDataService } from 'src/app/services/master-data.service';

import { ProductService } from 'src/app/services/product.service';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-pre-login-products',
  templateUrl: './pre-login-products.component.html',
  styleUrls: ['./pre-login-products.component.scss'],
})
export class PreLoginProductsComponent {
  private destroyed$ = new Subject<void>();
  assessmentProduct: any[] = [];
  allAssessmentProducts: any[] = [];
  assessmentProductCarousel: any[] = Array(4)
    .fill(0)
    .map((x, i) => i);

  purchasedProducts: any[] = [];
  purchasedProductCarousel: any[] = Array(3)
    .fill(0)
    .map((x, i) => i);
  filterType: any[] = [{ name: 'All', isChecked: true, subjectId: 'All' }];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private studentService: StudentService,
    private masterservice: MasterDataService
  ) {}

  ngOnInit() {
    let categoryId, examId;
    
    //products?categoryId=CUET&examId=CUET_SCIENCE
    this.activatedRoute.queryParams.subscribe((params: any) => {
      console.log(params);
      categoryId = params.categoryId;
      examId = params.examId;

      this.masterservice
        .getSubjectsByExamId(examId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: any) => {
          console.log(res.subjects);
          if (res.subjects.length > 1) {
            this.filterType = this.filterType.concat(res.subjects);
          } else {
            this.filterType = [...res.subjects];
            this.filterType[0] && (this.filterType[0].isChecked = true);
          }
        });

      this.productService
        .getProducts({
          keyFeature: 'ASSESSMENT',
          catagoryCode: categoryId,
          examId: examId,
          state: 'PUBLISHED',
          sort: ['rowCreatedDate dsc'],
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: any) => {
          console.log(res);
          this.assessmentProduct = res.content;
          this.allAssessmentProducts = res.content;
        });
    });
  }

  navigateToProductInfoSite(id: any) {
    if (id) {
      this.router.navigateByUrl('/post-login/assessment/product/' + id);
    }
  }
  applyFilter(flter: any) {
    this.filterType.map((x) => (x.isChecked = false));
    flter.isChecked = true;
    console.log(this.filterType, flter);

    /** Recommended product filter based on subject */
    let rPCarousel = Array(4)
      .fill(0)
      .map((x, i) => i);
    if (flter.subjectId !== 'All') {
      const filteredRecmProd = this.allAssessmentProducts.filter(
        (prod: any) => {
          return (
            Object.keys(prod).length > 0 &&
            prod.productLineItems[0].subjects.some(
              (sub: any) => sub.id === flter.subjectId
            )
          );
        }
      );
      this.assessmentProduct = filteredRecmProd;
      console.log(filteredRecmProd);
      //   if (filteredRecmProd.length > 0 && filteredRecmProd.length < 4) {
      //       rPCarousel.splice(0, this.assessmentProduct.length, ...filteredRecmProd);
      //       this.assessmentProductCarousel = rPCarousel.slice();
      //   } else if (filteredRecmProd.length == 0) {
      //       this.assessmentProductCarousel = rPCarousel.slice();
      //   } else {
      //       this.assessmentProductCarousel = filteredRecmProd.slice();
      //   }
    } else {
      this.assessmentProduct = this.allAssessmentProducts;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
