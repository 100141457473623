import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[appUsernameVal]',
    providers: [{ provide: NG_VALIDATORS, useExisting: UsernameValidator, multi: true }]
})
export class UsernameValidator implements Validator {

    //   @Input('appCompare') appCompare: AbstractControl;
    constructor() { }

    validate(control: AbstractControl): { [key: string]: any } | null {

        const validated: any = {};

        if (control.value.length < 4) {
            validated['length'] = '*Username must be at least 4 characters';
        }

        if (control.value.length > 32) {
            validated['length'] = '*Username must not exceed 32 characters';
        }

        const regStartUnderscore = new RegExp('^_+');
        if (String(control.value).match(regStartUnderscore)) {
            validated['startUnderscore'] = '*Username can not start with underscore';
        }

        const regEndUnderscore = new RegExp('_+$');
        if (String(control.value).match(regEndUnderscore)) {
            validated['endUnderscore'] = '*Username can not end with underscore';
        }

        const regStartNum = new RegExp('^[0-9]');
        if (String(control.value).match(regStartNum)) {
            validated['startNum'] = '*Username can not start with number';
        }

        const regSpecialChr = new RegExp('[`|~|!|@|#|$|%|\^|&|*|=|+|/|\\|?|>|<|,|(|)|{|}|[|]|\'|-|:|;|\"]');
        if (String(control.value).match(regSpecialChr)) {
            validated['specialChr'] = '*Special character not permitted';
        }

        const regDot = new RegExp('[.]');
        if (String(control.value).match(regDot)) {
            validated['dot'] = '*Dot(.) is not permitted';
        }

        const regSpace = new RegExp('[\\s]');
        if (String(control.value).match(regSpace)) {
            validated['space'] = '*Space is not permitted';
        }

        console.log(validated);

        return validated;
    }
}
