import { DeaultEnvironmentConfig } from "./default.env";

class DevelopmentEnvironmentConfig extends DeaultEnvironmentConfig {
  override production= false;
  apiUrl= 'http://localhost:4200';
  apiEndpoint='';
  //override rootApiUrl = 'http://ac875750291f44ed08afbbace93f5a7d-36990a83063b1bb4.elb.ap-south-1.amazonaws.com';

  override get authConfig() {
    return {
      ...super.authConfig, ... {
        //issuer: 'http://43.204.250.113:8080',
        realm: 'exam-factor'
      }
    };
  }



}

export const environment = new DevelopmentEnvironmentConfig();