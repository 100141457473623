import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-max-attempt-dialog',
  templateUrl: './max-attempt-dialog.component.html',
  styleUrls: ['./max-attempt-dialog.component.scss']
})
export class MaxAttemptDialogComponent {
 maxCount:any = 0;
  constructor(public dialogRef: MatDialogRef<MaxAttemptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
      this.maxCount = data.maxCount
    }
  copyCode(){

  }
  onClose(){
    this.dialogRef.close()
  }
}
