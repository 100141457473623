import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-refer-earn-dialog',
  templateUrl: './refer-earn-dialog.component.html',
  styleUrls: ['./refer-earn-dialog.component.scss']
})
export class ReferEarnDialogComponent {
  cCode:any="REFERCODE";
  constructor(public dialogRef: MatDialogRef<ReferEarnDialogComponent>) {}
  copyCode(){

  }
  onClose(){
    this.dialogRef.close()
  }
}
