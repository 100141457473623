import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from '../core';
import { SharedModule } from '../shared';
import { AuthHttpBearerInterceptor } from './util/auth.http.interceptor';







@NgModule({
    imports: [
        CommonModule,
        NgxSpinnerModule,
        SharedModule,
        CoreModule,
        NgbModule
    ],
    declarations: [
        
    ],
    exports: [],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                // { provide: AuthConfig, useValue: authConfig },
                // { provide: OAuthModuleConfig, useValue: authModuleConfig },
                // { provide: OAuthStorage, useFactory: storageFactory },
                // OidcConfigService,
                // {
                //   provide: APP_INITIALIZER,
                //   useFactory: configureAuth,
                //   deps: [OidcConfigService],
                //   multi: true,
                // },
                // {
                //     provide: APP_INITIALIZER,
                //     useFactory: initializeKeycloak,
                //     multi: true,
                //     deps: [KeycloakService],
                // }
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthHttpBearerInterceptor,
                    multi: true
                  },
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('ExamFactorLMSAuthModule is already loaded. Import it in the AppModule only');
        }
    }
}
