
<section class="post-login-assessment-landing">
    <div class="row d-none d-lg-flex">
        <div class="col-12 ps-0 mb-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    
                    <li class="breadcrumb-item active" aria-current="page" style="padding-left: 20px;">Product List</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row">
      
        <div class="col-12">
            <div class="filter-section mb-4">
                <div class="ef-lms-toggle-btn" *ngFor="let f of filterType">
                    <input type="checkbox" class="btn-check" id="{{'btn-check'+f.subjectId}}" autocomplete="off"
                        [(ngModel)]="f.isChecked" (ngModelChange)="applyFilter(f)">
                    <label class="btn btn-dark" for="{{'btn-check'+f.subjectId}}"><span>{{f.name}}</span></label>
                </div>
            </div>
        </div>

        

      

        <div class="col-12" *ngIf="assessmentProduct.length">
            <div class="product-purchased multi-screen-carousel mb-4">
                <div class="d-flex justify-content-start align-items-center"> 
                    <!-- <span class="title">Recommended</span> -->
                    <!-- <button type="button" class="btn see-more-btn" *ngIf="assessmentProduct.length > 4" (click)="seeAllRecommendation()">SEE ALL -->

                    
                </div>
                    <div class="row">
                        <div class="col-md-3" *ngFor="let product of assessmentProduct">
                            <div class="product-item" (click)="navigateToProductInfoSite(product.id)"
                                [ngStyle]="{'cursor': !product.imageUrl? 'default': 'cursor'}">
                                <div class="product-item-content">
                                    <div class="tiles" [ngStyle]="{ 'background': !product.imageUrl ? 'transparent' : '' }">
                                        <div class="row" [ngClass]="{'invisible': !product.imageUrl}">
                                            <div
                                                class="col-6 order-2 col-lg-12 order-lg-1 d-flex justify-content-center align-items-center d-lg-block">
                                                <div class="p-3 p-lg-0">
                                                    <div class="sm-banner-banner-height">
                                                    <img class="banner-sm" [src]="product.imageUrl" />
                                               </div>
                                                </div>
                                            </div>
                                            <div class="col-6 order-1 col-lg-12 order-lg-2">
                                                <div class="p-3">
                                                    <div class="caption mb-1" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;color: white;">{{ product.productName }}</div>
                                                    <div class="sub-caption mb-2">{{ product.studentTenure }} months</div>
                                                    <div class="latest-price mb-1">
                                                        <!-- <img src="../../../../../assets/icons/assessment/coin.svg" />
                                                        <span class="offer ms-1">120</span>
                                                        <span class="ms-1">or</span> -->
                                                        <span class="ms-1 fw-bold">₹{{ product.totalPrice }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                
            </div>
        </div>

    </div>
</section>