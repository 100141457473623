<span
  class="closeBtnaction"
  mat-dialog-actions
  align="end"
  style="z-index: 4000"
  (click)="onBottomSheetclose()"
>
  <img src="../../../../assets/close-icon.png" width="15" alt="" />
</span>
<div class="container-fluid voucher-dialog">
  <div class="edit-header">
    <img src="./assets/icons/header/coin.svg" alt="coin" />
    You have {{ coinCount }} coins to redeem
  </div>
  <div class="sub-header">Buy Tests</div>
  <div class="plan-details">
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Realistic CUET Experience</span>
        <span
          >Build your skills with Mock tests designed as per the NTA
          standards.</span
        >
      </div>
    </div>
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Detailed Analysis</span>
        <span
          >Get detailed insights into your performance such as areas of
          strength, improvement, percentile and more</span
        >
      </div>
    </div>
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Live Mocks</span>
        <span
          >Live the CUET experience with Live Mocks that replicate exam
          conditions to get you ready for the Exam Day</span
        >
      </div>
    </div>
    <div class="inst">
      <img src="./assets/explore-product-book.svg" />
      <div class="details">
        <span>Skill Development</span>
        <span
          >The mocks provide comprehensive topical coverage and help you
          progressively build your skills</span
        >
      </div>
    </div>
    <button
      mat-raised-button
      class="commonBtnStyle"
      (click)="navigateToTests()"
    >
      <span> Buy Now </span>
      <img src="./assets/icons/assessment/start-arrow-black.svg" />
    </button>
  </div>
</div>
<!-- <div class="container-fluid voucher-dialog" style="padding: 0px">
  <div class="row">
    <div class="col-12 mt-3">
      <div class="edit-header">
        <img src="../../../../assets/icons/header/coin.svg" />
        You have {{ coinCount }} coins to redeem
      </div>
    </div>
    <div class="col-12 sub-header">SELECT YOUR OPTION</div>
    <div class="col-12" style="margin-top: 10px">
      <span class="edit-header" style="margin-bottom: 5px"
        >Buy Testszx zxc v zxcv</span
      >
      <div class="plan-details">
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2">CUET simulation: NTA-aligned closely.</span>
        </div>
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2"
            >Progressive skill development with comprehensive tests.</span
          >
        </div>
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2"
            >Detailed analysis, personalized improvement suggestions.</span
          >
        </div>
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2">24/7 access for flexible study sessions.</span>
        </div>
        <p class="text-left mt-4 mb-0">
          <button
            mat-raised-button
            class="btn commonBtnStyle"
            (click)="navigateToTests()"
          >
            <span> Explore Tests </span> &nbsp;
            <img
              class="btn-right-arrow-position"
              src="../../../../assets/icons/assessment/start-arrow-black.svg"
            />
          </button>
        </p>
      </div>
    </div>
    <div class="col-12" style="margin-top: 10px">
      <span class="edit-header">Subscribe to Practice Tools</span>
      <div class="plan-details">
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2">100k+ Question bank.</span>
        </div>
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2">Identify weak and strong areas.</span>
        </div>
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2">Practice at your own pace.</span>
        </div>
        <div class="inst">
          <img style="height: 13px" src="../../../../assets/Path4.png" />
          <span class="ms-2">Concept-level knowledge journey.</span>
        </div>
        <p class="text-left mt-4 mb-0">
          <button
            mat-raised-button
            class="btn commonBtnStyle"
            [disabled]="subStatus"
            (click)="viewPlans()"
          >
            <span>Select Your Plan</span> &nbsp;
            <img
              class="btn-right-arrow-position"
              src="../../../../assets/icons/assessment/start-arrow-black.svg"
            />
          </button>
        </p>
      </div>
    </div>
  </div>
</div> -->
