<section class="assessment-submit-modal">
    <div class="modal-container">
        <button style="position: absolute;right: -4px;top: -38px;" (click)="activeModal.dismiss('Cross click')"
            type="button" class="btn close-btn" aria-label="Close">
            <img src="../../../../../../assets/icons/assessment/close-icon.svg" />
        </button>
        <div class="header">
            <span class="header-content"> tools</span>
        </div>

        <div class="body" *ngIf="selectedPlan">
            <label class="section-name" style="margin-bottom: 10px;">Select a plan</label>
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="let plan of planList" class=" rcorners" (click)="onSelectPlan(plan)">
                        <label class="btn text-start plan-data"
                            [ngClass]="selectedPlan == plan ? 'selected-plan' : 'plan-data'">

                            <span class="ef-q-content readonly-content" style="font-weight: 600;">
                                &#8377; {{plan.totalPriceAfterDiscount ? plan.totalPriceAfterDiscount :
                                plan.totalPrice}}
                                <span class="duration">{{plan.studentTenure}} months</span>
                            </span>
                        </label>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="plan-part">
                        <h3 class="plan-duration">{{selectedPlan.studentTenure}}-Month Plan</h3>
                        <div class="plan-det" style="margin-left: 15px;margin-bottom: 10px;font-size: 80%;"
                            [innerHTML]="selectedPlan.productDescription"></div>

                    </div>
                </div>
            </div>
        </div>

        <div class="footer" *ngIf="selectedPlan">

            <div class="col-12 ps-lg-1" style="display: flex;justify-content: flex-end;">
                <button type="button" class="btn fill-btn" (click)="makePayment()">Make Payment</button>
            </div>
        </div>

        <div *ngIf="!selectedPlan" class="d-flex justify-content-center align-items-center p-5">
            No Plans Available
        </div>
    </div>
</section>