<!-- <div class="lms-story">
    <div class="progress-container">
        <div class="wrapper">
            <ng-container *ngIf="(counter$ | async) as progress1">
                <div class="progress-bar" [style.width.%]="progress1"></div>
            </ng-container>
        </div>
        <div class="wrapper ms-2">
            <ng-container *ngIf="(counter$ | async) as progress2">
                <div class="progress-bar" [style.width.%]="progress2"></div>
            </ng-container>
        </div>
    </div>
    <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false" [animation]=" false"
        [interval]="5000" [pauseOnFocus]="false">
        <ng-template ngbSlide *ngFor="let story of stories">
            <img [src]="story" class="" alt="Random first slide" />
        </ng-template>
    </ngb-carousel>
</div> -->
<div class="lms-story">
    <button type="button" class="btn close-btn" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
        <img src="../../../../assets/icons/practice/close-btn.svg" />
    </button>
    <wc-stories [images]="imageData" withShadow class="aaa" #lmsStory>
        <wc-stories-story *ngFor="let story of imageData; let idx = index" [src]="story" #storyImage>
        </wc-stories-story>
    </wc-stories>
    <button class="btn left-btn" (click)="prevStory()" #leftBtn>
        <img src="../../../../assets/icons/practice/story-left-icon.svg" />
    </button>
    <button class="btn right-btn" (click)="nextStory()">
        <img src="../../../../assets/icons/practice/story-right-btn.svg" />
    </button>

    <div class="info-section" *ngIf="stories && stories.length > 0 && cref.startAt > -1">
        <div class="title">{{stories[cref.startAt].title}}</div>
        <div class="description" [innerHTML]="stories[cref.startAt].description"></div>
    </div>
    <!-- <div class="info-section" *ngIf="stories && stories.length > 0 && cref.startAt > -1">
        <div class="title">{{storyTitle}}</div>
        <div>{{storyDesription}}</div>
    </div> -->
</div>