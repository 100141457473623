import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-report-success-msg',
  templateUrl: './report-success-msg.component.html',
  styleUrls: ['./report-success-msg.component.scss']
})
export class ReportSuccessMsgComponent {
  constructor(public dialogRef: MatDialogRef<ReportSuccessMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
    }
  copyCode(){

  }
  onClose(){
    this.dialogRef.close()
  }
}
