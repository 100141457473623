import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbRatingModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxInputStarRatingModule } from '@ngx-lite/input-star-rating';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
// components
// imports
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DragScrollModule } from 'ngx-drag-scroll';
import { HumanizePipe } from '../core/pipes/humanize.pipe';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { InnerIproduct } from './components/product-slider/inner-product/inner-product.component';
import { ProductSlider } from './components/product-slider/product-slider.component';
import { IncrementalCounterComponent } from './components/incremental-counter/incremental-counter.component';

// Directives
import { ZoomableDirective } from './directives/zoomable.directive';
// Pipes
import { ImageCardComponent } from './components/image-card/image-card.component';
import { ReviewcardComponent } from './components/reviewcard/reviewcard.component';
import { KeysPipe } from './pipes/keys.pipe';
import { TimePipe } from './pipes/time.pipe';
import { NgChartsModule } from 'ng2-charts';



@NgModule({
  declarations: [
    // components
    // pipes
    KeysPipe,
    HumanizePipe,
    TimePipe,
    ZoomableDirective,
    ProductSlider,
    InnerIproduct,
    ImageCardComponent,
    CountdownTimerComponent,
    ReviewcardComponent,
    IncrementalCounterComponent
  ],
  exports: [
    // components
    // modules
    CommonModule,
    TranslateModule,
    BsDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    // pipes
    KeysPipe,
    HumanizePipe,
    TimePipe,
    DragScrollModule,
    ZoomableDirective,
    ProductSlider,
    DragScrollModule,
    ImageCardComponent,
    CountdownTimerComponent,
    ReviewcardComponent,
    NgChartsModule,
    NgbRatingModule,
    NgbDropdownModule,
    IncrementalCounterComponent,
    NgbModule
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule.forRoot(),
    BsDropdownModule.forRoot(),
    DragScrollModule,
    RouterModule,
    NgxInputStarRatingModule,
    NgChartsModule,
    NgbModule,
    NgbRatingModule,
    NgbDropdownModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule
  ],
  providers: []
})
export class SharedModule { }
