import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class CommonConfigService {

    constructor(
    ) { }

    navItems = [
        {
            "name": "Dashboard",
            "url": "/home/dashboard",
            "icon": "fa fa-tachometer",
            "roles": ["*"]
        },
        {
            "name": "Mock",
            "url": "/home/mock",
            "icon": "fa fa-file-text-o",
            "roles": ["STUDENT"]
        },
        {
            "name": "Exams",
            "url": "/home/exam",
            "icon": "fa fa-pencil-square-o",
            "roles": ["STUDENT"]
        }
    ]

    getNavItems(role: string): Observable<any> {
        const roleNavItems = this.navItems.filter( ele => ele.roles.includes('*') || ele.roles.includes(role))
        return of(roleNavItems);
    }
}
