import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreLoginProductsComponent } from './modules/shared/pre-login-products/pre-login-products.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'post-login',
        pathMatch: 'full',
    },
    {
        path: 'products',
        component: PreLoginProductsComponent
    },
    {
        path: 'assessment-player',
        loadChildren: () => import('./modules/feature/assessment-player/exam/exam.module').then(m => m.ExamModule),
        data: {
            userTypes: ['*']
        }
    },
    {
        path: 'practice-player',
        loadChildren: () => import('./modules/feature/practice-player/exam/exam.module').then(m => m.ExamModule),
        data: {
            userTypes: ['*']
        }
    },
    {
        path: 'pre-login',
        loadChildren: () => import('./modules/feature/pre-login/pre-login.module').then(m => m.PreLoginModule),
        data: {
            userTypes: ['*']
        }
    },

    {
        path: 'login', 
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        data: {
            userTypes: ['*']
        }
    },
  
    {
        path: 'post-login',
        loadChildren: () => import('./modules/feature/post-login/post-login.module').then(m => m.PostLoginModule),
        data: {
            userTypes: ['*']
        }
    },
    {
        path: 'home',
        loadChildren: () => import('./modules/feature/profile-landing/profile-landing.module').then(m => m.ProfileLandingModule),
        data: {
            userTypes: ['*']
        }
    },
    {
        path: 'coins-view',
        loadChildren: () => import('./modules/feature/coin-landing/coin.module').then(m => m.CoinModule),
        data: {
            userTypes: ['*']
        }
    },
    {
        path: 'payment',
        loadChildren: () => import('./mobile-payment/mobile-payment.module').then(m => m.MobilePaymentModule),
        data: {
            userTypes: ['*']
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'enabledBlocking'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
