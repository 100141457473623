import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Exam } from '../models/exam';
import { ExamSummery } from '../models/exam.summery';
import { ExamResult } from '../models/exam.result';


@Injectable()
export class ExamService {


    constructor(private http: HttpClient) { }


    getExams(studentId: string): Observable<ExamSummery[]> {
        return of([{
            "testId": 100,
            "name": "Sample Test",
            "code": "SAMPLE_TEST",
            "duration": 180,
            "languageDispalyName": "English",
            "subjectDispalyName": "Computer"
        }]);
        /*  return this.http.get(`${environment.apiEndpoint}/v1/students/${studentId}/exams`)
             .pipe(
                 map(res => res as ExamSummery[])
             ); */
    }

    /* getExamDetails(examCode: string): Observable<Exam> { */
    getExamDetails(examCode: string): Observable<any> {
        /* return this.http.get(`${environment.apiEndpoint}/v1/exams?examCode=${examCode}`)
            .pipe(
                map(res => res as Exam)
            ); */
        return of(
            {
                "name": "Sample Test",
                "code": "SAMPLE_TEST",
                "duration": 180,
                "languageCode": "ENG",
                "questions": [{
                    "id": 1,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Who is called ‘the father of endocrinology’?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 1,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "E.H.Starling,",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 2,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "William Bayliss,",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 3,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thomas Addison",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 4,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Peterson",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 2,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Milk releasing hormone is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 5,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Oxytocin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 6,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Vasopresin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 7,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "PRH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 8,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "TSH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 3,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "First persons to coined the term ‘hormone’ are-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 9,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Charles Darwin and Francis Darwin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 10,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Sachs and Went",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 11,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Boysen and Jensen",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 12,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "William Bayliss and Ernest Starling",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 4,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which two hormones are released from the Neurohypophysis-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 13,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ADH and GH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 14,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ACTH and TSH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 15,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ADH and Oxytocin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 16,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "TRH and CRH.",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 5,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Hormones released by nerve cells of the ___________ regulate hormones secreted by the _________",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 17,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Hypothalamus,Adenohypophysis",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 18,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Hypothalamus,Neurohypophysis",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 19,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adenohypophysis,Hypothalamus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 20,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cerebellum,Neurohypophysis,",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 6,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "What is the funcion of Insulin?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 21,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "agonistic to glucagon",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 22,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "decreases glycogen storage in liver and muscle",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 23,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "reduces Hyperglycemia",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 24,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "all of the above",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 7,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which of these hormones is secreted in the liver?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 25,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Renin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 26,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Somatomedin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 27,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Erythropoietin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 28,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Oestrogen",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 8,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "What is the name of the stalk that connects the Hypothalamus to the Pituitary?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 29,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cerebral Corlex",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 30,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adenohypophysis",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 31,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Corpus callosum",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 32,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Infundibulum",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 9,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "What is another name for Thyroxine?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 33,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Triiodothyronine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 34,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Tetraidothyronine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 35,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyrocalcitonine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 36,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyromine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 10,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The first hormone isolated(1902) was-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 37,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyroxine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 38,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Vasopressin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 39,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Secretin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 40,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenaline",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 11,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Islets of Langerhans are found in-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 41,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adenohypophysis",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 42,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thymus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 43,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Pancreas",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 44,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Pituitary.",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 12,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Deficiency of Thyroid hormone in adults causes-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 45,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Addison's disease",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 46,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Myxoedema",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 47,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Graves disease",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 48,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Cretinism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 13,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Testosterone is secreted by-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 49,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Leydig cells",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 50,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Sertoli cells",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 51,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Histocyte",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 52,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Primary spermatocyte.",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 14,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "HCG is secreted by-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 53,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Placenta",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 54,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Ovary",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 55,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thymus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 56,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyroid",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 15,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "___________is due to the hypersecretion of Adrenal cortex-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 57,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Addisons disease",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 58,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Graves disease",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 59,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Down's Syndrome",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 60,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Caushing Syndrome",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 16,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Number of hormones secreted from Adenohypophysis-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 61,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "6",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 62,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "5",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 63,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "4",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 64,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "2",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 17,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The hormone secreted from human brain as well as from digestive system is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 65,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Gastrine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 66,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Secretin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 67,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Vasopressin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 68,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Somatostatin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 18,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Goitre is caused by-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 69,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Excess secretion of Thyroxin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 70,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Deficiency of Iodine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 71,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Over eating",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 72,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Detective growth hormone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 19,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The contraction of uterus  is due to secretion of-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 73,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "progesterone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 74,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Vasopressin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 75,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Oxytocin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 76,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenaline",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 20,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The hormone helps in RBC production is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 77,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyroxine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 78,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenaline",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 79,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Progesterone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 80,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Testosterone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 21,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The hormone regulates the secretion of ACTH from anterior Pituitary is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 81,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "ADH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 82,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ICSH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 83,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "CRH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 84,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "MRH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 22,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Hypersecretion of STH causes-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 85,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Acromegaly",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 86,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "grave's disease",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 87,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cretinisim",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 88,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Myxoedema",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 23,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following is not a local hormone?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 89,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Gastrine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 90,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Histamine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 91,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Bradykinine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 92,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Epinephrine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 24,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Concentration of the urine is controlled by-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 93,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "MSH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 94,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ADH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 95,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Oxytocin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 96,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "ACTH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 25,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Damage to thymus in children may lead to-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 97,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Loss of antibody mediated immunity",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 98,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Reduction in  stem cell production",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 99,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Reduction of Haemoglobin content of blood",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 100,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "loss of cell-mediated immunity",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 26,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "ACTH stimulates the Adrenal Cortex to release a group of hormones called-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 101,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Mineralocorticoids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 102,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Glucocorticoids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 103,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Endorphins",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 104,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "glucagon",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 27,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The hormone known to participate in metabolism of calcium and phosphorus is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 105,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Mineralocorticoids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 106,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "glucagon",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 107,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "glucocorticoids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 108,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Calcitonin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 28,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Deficiency of Adrenal cortex hormones results in",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 109,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Tetany",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 110,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Acromegaly",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 111,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Addisons disease",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 112,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Cretinism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 29,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "In addition to Thyroxine,thyroid gland produces-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 113,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "thyroid-stimulating hormone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 114,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cortisol",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 115,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Calcitonin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 116,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "GTH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 30,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "ADH is synthesized in the-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 117,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Anterior pituitary",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 118,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Posterior pituitary",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 119,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Hypothalamus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 120,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thalamus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 31,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The Adrenal cortex produces only-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 121,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Polypeptides",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 122,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Steroids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 123,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Amines",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 124,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Glycoproteins",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 32,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which of the following hormones promotes Hypoglycemia?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 125,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Insulin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 126,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "glucagon",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 127,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "STH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 128,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Epinephrine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 33,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which of the following stimulates the production of progesterone by the corpus luteum?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 129,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "LH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 130,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "LTH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 131,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "PRL",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 132,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "FSH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 34,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "In the Pancreas, which are the cells that secrete somatostalin?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 133,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Alpha",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 134,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Beta",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 135,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Delta",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 136,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Gamma",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 35,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which hormone does not increase basal  metabolic rate?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 137,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Thyroxine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 138,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Progesterone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 139,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Testosterone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 140,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenaline",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 36,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following hormones participates in the metamorphosis of toad?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 141,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "STH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 142,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "GTH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 143,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyroxine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 144,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Adrenaline",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 37,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Most hormones of the endocrine system are regulated by a",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 145,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Positive feedback mechanism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 146,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Negative feedback mechanism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 147,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Hormone-receptor complex",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 148,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Hormone-gene complex",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 38,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The glucagon is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 149,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Accelerates the conversion of glycogen into glucose",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 150,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "slows down glucose formation from lactic acid",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 151,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "decreases the conversion of glycogen into glucose",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 152,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "speeds up protein synthesis within cells.",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 39,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which hormones of the adrenal glands supplement the sex hormones from the gonads?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 153,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Mineralocorticoids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 154,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Glucocorticoids",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 155,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Androgens",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 156,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenaline and Noradrenalin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 40,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The primary target of the releasing and inhibiting hormones of the Hypothalamus is the-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 157,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "liver and adipose tissue",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 158,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Gonads",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 159,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Anterior Pituitary",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 160,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Bone marrow.",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 41,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which of the following hormones are responsible for the fight,flight and fright(3f) response?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 161,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Thyroxine and Melatonin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 162,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Insulin and glucagon",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 163,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Oestrogen and progesterone",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 164,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenalin and Noradrenalin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 42,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "How do hormones from Thyroid and Parathyroid regulate the calcium concentration of the blood?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 165,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Calcitonin lowers blood calcium,parathyroid hormone raises blood calcium",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 166,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Parathyroid hormone lowers blood calcium,calcitonin raises  blood calcium",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 167,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Thyroxine and Triiodothyronine together regulate calcium levels,as needs dictate,",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 168,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Both parathyroid hormone and the three thyroid hormones function to regulate blood calcium levels.",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 43,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Another name for GH-IH is",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 169,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Oxytocin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 170,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Vasopressin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 171,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Somatostatin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 172,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Dopamine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 44,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following does not act as a neurotransmitter?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 173,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ACTH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 174,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "STH",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 175,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Dopamine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 176,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Noradrenalin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 45,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following does not help in glycolysis?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 177,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Insulin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 178,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "glucagon",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 179,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Adrenalin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 180,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Noradrenalin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 46,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following slows down the digestive process?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 181,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "thyroxine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 182,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "gastrin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 183,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Secretin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 184,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Somatostatin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 47,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Another name for Prolactin Inhibiting hormone(PIH) is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 185,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cortisol",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 186,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Calcitrol",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 187,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Dopamine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 188,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Oxytocin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 48,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The smallest endocrine gland of human body is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 189,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Pituitary",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 190,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Parathyroid",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 191,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Pineal",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 192,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Hypothalamus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 49,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The type of movement of plant influenced by Auxin is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 193,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Nyctinasty",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 194,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "photoropism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 195,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "photonasty",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 196,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Hydrotropism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 50,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of following hormones does not help in parthenocarpy?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 197,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Zeatin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 198,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "IAA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 199,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "IBA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 200,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "NAA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 51,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Natural cytokinin is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 201,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Zeanin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 202,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Zea kinase",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 203,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Zeatin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 204,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Dormin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 52,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "The hormone takes role for determination of sex in a plant is",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 205,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Gibberellin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 206,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Auxin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 207,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cytokinins",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 208,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Vernalin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 53,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Seed germination is induced by which hormone?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 209,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Gibberellic acid",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 210,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Auxin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 211,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cytokinins",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 212,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ABA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 54,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which hormone delay leaf senescence?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 213,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Auxin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 214,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "gibberellin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 215,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cytokinins",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 216,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "IAA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 55,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Clinostat demonstrates-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 217,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Geotropism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 218,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Hydrotropism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 219,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Thigmotropism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 220,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Phototropism",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 56,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Irritability is-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 221,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Respond to stimulus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 222,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Non respond to stimulus",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 223,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Changes within body",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 224,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "None of these",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 57,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Gibberllins in pure crystalline form were first isolated by-",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 225,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Kurosawa",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 226,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Yabuta & Sumiki",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 227,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Went",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 228,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Sachs",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 58,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following stimulates mitotic cell division in the meristematic tissue",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 229,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Gibberellin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 230,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "ABA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 231,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Cytokinins",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 232,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Ethylene",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 59,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "Which one of the following is an anti-auxin.",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 233,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "ABA",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 234,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Benzyl Amino Purine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 235,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Napthoxy-acetic acid",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 236,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "tri-iodo-benzoic acid",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }, {
                    "id": 60,
                    "subjectChapterCode": "LSC_CCLO",
                    "languageCode": "en",
                    "answerRenderType": "RDOBTN",
                    "textAnswer": "",
                    "questionCategoryCode": "M",
                    "classStandardType": "CLS-10",
                    "marks": 1,
                    "questionContents": [{
                        "data": "From which amino acid,Auxin is derived?",
                        "contentType": "Text",
                        "order": 1
                    }],
                    "optionChoices": [{
                        "id": 237,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Phenylalanine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 238,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Tryptophan",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 239,
                        "correct": false,
                        "answerChoiceContents": [{
                            "data": "Tyrosin",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }, {
                        "id": 240,
                        "correct": true,
                        "answerChoiceContents": [{
                            "data": "Arginine",
                            "contentType": "Text",
                            "order": 1
                        }]
                    }],
                    "private": false,
                    "published": true
                }]

            }
        );
    }
    /**
    *
    *
    * @param {User} user
    * @param {String} folderName
    * @returns {Observable<any>}
    * @memberof ExamService
    */

    submitTest(ansPayload: any, studentId: any, testId: any): Observable<any> {
        return of(testId);
        // return this.http.post(environment.apiEndpoint + `/v1/students/${studentId}/exams/${testId}`, ansPayload);
    }


    getTestResult(studentId: any, testId: any): Observable<ExamResult> {
        return this.http.get(environment.apiEndpoint + `/v1/students/${studentId}/exams/${testId}`)
            .pipe(
                map(res => {
                    return res as ExamResult;
                })
            );
    }

    getStudentExams(language: string): Observable<any> {

        return this.http.get(environment.apiEndpoint + `/v1/exams/language?languageCode=${language}`)
            .pipe(
                map(res => {

                    return res;
                })
            );
    }

    assignExam(studentId: any, questionSetCd: any, vourcherCd: any): Observable<any> {
        return this.http.post(environment.apiEndpoint + `/v1/students/${studentId}/questionset`, {
            "questionSetCd": questionSetCd,
            "vourcherCd": vourcherCd
        }).pipe(
            map(res => {
                return res;
            })
        );
    }

}
