import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var window: any;

export interface PhotosApi {
    albumId?: number;
    id?: number;
    title?: string;
    url?: string;
    thumbnailUrl?: string;
}

@Component({
    selector: 'app-story-carousel',
    templateUrl: './story-carousel.component.html',
    styleUrls: ['./story-carousel.component.scss']
})
export class StoryCarouselComponent implements OnChanges, OnInit {

    /*cardInfosContainerEl: any;
    cardsContainerEl: any;
    appBgContainerEl: any;
    gsap: any;
    imagesLoaded: any;
  
    ngOnInit(): void {
      this.cardInfosContainerEl = document.querySelector(".info__wrapper");
      this.cardsContainerEl = document.querySelector(".cards__wrapper");
      this.appBgContainerEl = document.querySelector(".app__bg");
  
      this.initCarousel();
    }
  
    initCarousel() {
  
      console.clear();
  
      this.gsap = window.gsap;
      this.imagesLoaded = window.imagesLoaded;
  
      const buttons: any = {
        prev: document.querySelector(".btn--left"),
        next: document.querySelector(".btn--right"),
      };
  
  
  
  
      // buttons.next.addEventListener("click", () => this.swapCards("right", buttons));
  
      // buttons.prev.addEventListener("click", () => this.swapCards("left", buttons));
  
      this.initCardEvents();
  
  
      const waitForImages = () => {
        const imgRef: any = document.querySelectorAll("img");
        const images = [...imgRef];
        const totalImages = images.length;
        let loadedImages = 0;
        const loaderEl = document.querySelector(".loader span");
  
        this.gsap.set(this.cardsContainerEl.children, {
          "--card-translateY-offset": "100vh",
        });
        this.gsap.set(this.cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
          translateY: "40px",
          opacity: 0,
        });
        this.gsap.set([buttons.prev, buttons.next], {
          pointerEvents: "none",
          opacity: "0",
        });
  
        images.forEach((image) => {
          this.imagesLoaded(image, (instance) => {
            if (instance.isComplete) {
              loadedImages++;
              let loadProgress = loadedImages / totalImages;
  
              this.gsap.to(loaderEl, {
                duration: 1,
                scaleX: loadProgress,
                backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%`,
              });
  
              if (totalImages == loadedImages) {
                this.gsap.timeline()
                  .to(".loading__wrapper", {
                    duration: 0.8,
                    opacity: 0,
                    pointerEvents: "none",
                  })
                  .call(() => this.init(buttons));
              }
            }
          });
        });
      };
  
      waitForImages();
    }
  
    swapCards(direction) {
      const buttons: any = {
        prev: document.querySelector(".btn--left"),
        next: document.querySelector(".btn--right"),
      };
  
      const currentCardEl = this.cardsContainerEl.querySelector(".current--card");
      const previousCardEl = this.cardsContainerEl.querySelector(".previous--card");
      const nextCardEl = this.cardsContainerEl.querySelector(".next--card");
  
      const currentBgImageEl = this.appBgContainerEl.querySelector(".current--image");
      const previousBgImageEl = this.appBgContainerEl.querySelector(".previous--image");
      const nextBgImageEl = this.appBgContainerEl.querySelector(".next--image");
  
      this.changeInfo(direction, buttons);
      this.swapCardsClass(currentCardEl, previousCardEl, nextCardEl, currentBgImageEl, previousBgImageEl, nextBgImageEl, direction);
  
      this.removeCardEvents(currentCardEl);
  
  
    }
  
    swapCardsClass(currentCardEl, previousCardEl, nextCardEl, currentBgImageEl, previousBgImageEl, nextBgImageEl, direction) {
      currentCardEl.classList.remove("current--card");
      previousCardEl.classList.remove("previous--card");
      nextCardEl.classList.remove("next--card");
  
      currentBgImageEl.classList.remove("current--image");
      previousBgImageEl.classList.remove("previous--image");
      nextBgImageEl.classList.remove("next--image");
  
      currentCardEl.style.zIndex = "50";
      currentBgImageEl.style.zIndex = "-2";
  
      if (direction === "right") {
        previousCardEl.style.zIndex = "20";
        nextCardEl.style.zIndex = "30";
  
        nextBgImageEl.style.zIndex = "-1";
  
        currentCardEl.classList.add("previous--card");
        previousCardEl.classList.add("next--card");
        nextCardEl.classList.add("current--card");
  
        currentBgImageEl.classList.add("previous--image");
        previousBgImageEl.classList.add("next--image");
        nextBgImageEl.classList.add("current--image");
      } else if (direction === "left") {
        previousCardEl.style.zIndex = "30";
        nextCardEl.style.zIndex = "20";
  
        previousBgImageEl.style.zIndex = "-1";
  
        currentCardEl.classList.add("next--card");
        previousCardEl.classList.add("current--card");
        nextCardEl.classList.add("previous--card");
  
        currentBgImageEl.classList.add("next--image");
        previousBgImageEl.classList.add("current--image");
        nextBgImageEl.classList.add("previous--image");
      }
    }
  
    changeInfo(direction, buttons) {
      let currentInfoEl = this.cardInfosContainerEl.querySelector(".current--info");
      let previousInfoEl = this.cardInfosContainerEl.querySelector(".previous--info");
      let nextInfoEl = this.cardInfosContainerEl.querySelector(".next--info");
  
      this.gsap.timeline()
        .to([buttons.prev, buttons.next], {
          duration: 0.2,
          opacity: 0.5,
          pointerEvents: "none",
        })
        .to(
          currentInfoEl.querySelectorAll(".text"),
          {
            duration: 0.4,
            stagger: 0.1,
            translateY: "-120px",
            opacity: 0,
          },
          "-="
        )
        .call(() => {
          this.swapInfosClass(direction, currentInfoEl, previousInfoEl, nextInfoEl);
        })
        .call(() => this.initCardEvents())
        .fromTo(
          direction === "right"
            ? nextInfoEl.querySelectorAll(".text")
            : previousInfoEl.querySelectorAll(".text"),
          {
            opacity: 0,
            translateY: "40px",
          },
          {
            duration: 0.4,
            stagger: 0.1,
            translateY: "0px",
            opacity: 1,
          }
        )
        .to([buttons.prev, buttons.next], {
          duration: 0.2,
          opacity: 1,
          pointerEvents: "all",
        });
  
  
    }
  
    swapInfosClass(direction: any = "", currentInfoEl, previousInfoEl, nextInfoEl) {
      currentInfoEl.classList.remove("current--info");
      previousInfoEl.classList.remove("previous--info");
      nextInfoEl.classList.remove("next--info");
  
      if (direction === "right") {
        currentInfoEl.classList.add("previous--info");
        nextInfoEl.classList.add("current--info");
        previousInfoEl.classList.add("next--info");
      } else if (direction === "left") {
        currentInfoEl.classList.add("next--info");
        nextInfoEl.classList.add("previous--info");
        previousInfoEl.classList.add("current--info");
      }
    }
  
    removeCardEvents(card) {
      card.removeEventListener("pointermove", this.updateCard);
    }
  
    updateCard(e) {
      const card = e.currentTarget;
      const box = card.getBoundingClientRect();
      const centerPosition = {
        x: box.left + box.width / 2,
        y: box.top + box.height / 2,
      };
      let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
      this.gsap.set(card, {
        "--current-card-rotation-offset": `${angle}deg`,
      });
      const currentInfoEl = this.cardInfosContainerEl.querySelector(".current--info");
      this.gsap.set(currentInfoEl, {
        rotateY: `${angle}deg`,
      });
    }
  
    resetCardTransforms(e) {
      const card = e.currentTarget;
      const currentInfoEl = this.cardInfosContainerEl.querySelector(".current--info");
      this.gsap.set(card, {
        "--current-card-rotation-offset": 0,
      });
      this.gsap.set(currentInfoEl, {
        rotateY: 0,
      });
    }
  
    initCardEvents() {
      const currentCardEl = this.cardsContainerEl.querySelector(".current--card");
      currentCardEl.addEventListener("pointermove", this.updateCard);
      currentCardEl.addEventListener("pointerout", (e) => {
        this.resetCardTransforms(e);
      });
    }
  
    init(buttons) {
  
      let tl = this.gsap.timeline();
  
      tl.to(this.cardsContainerEl.children, {
        delay: 0.15,
        duration: 0.5,
        stagger: {
          ease: "power4.inOut",
          from: "right",
          amount: 0.1,
        },
        "--card-translateY-offset": "0%",
      })
        .to(this.cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
          delay: 0.5,
          duration: 0.4,
          stagger: 0.1,
          opacity: 1,
          translateY: 0,
        })
        .to(
          [buttons.prev, buttons.next],
          {
            duration: 0.4,
            opacity: 1,
            pointerEvents: "all",
          },
          "-=0.4"
        );
    }
  */

    @Input('storyData') storyData: any;

    apiData: any;
    limit: number = 10; // <==== Edit this number to limit API results
    customOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        center: true,
        dots: true,
        autoHeight: true,
        autoWidth: true,
        items : 1,
        nav: true,
        // navText: ["<div class='nav-button owl-prev prev-icon'><img src='../../../../assets/icons/home/story-pre-icon.svg' /></div>", "<div class='nav-button owl-next'><img src='../../../../assets/icons/home/story-next-icon.svg'</div>"],
        navText: ["<div class='nav-button owl-prev'>‹</div>", "<div class='nav-button owl-next'>›</div>"],
        responsive: {
          0: {
            items: 3,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 3,
          }
        }
    };

    constructor(
        private readonly http: HttpClient,
        public activeModal: NgbActiveModal
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['storyData'] && changes['storyData'].currentValue) {
            if (this.storyData.length > 2) {
                this.customOptions.loop = true;
                this.customOptions.autoplay = true;
            } else {
                this.customOptions.loop = false;
                this.customOptions.autoplay = false;
            }
        }
    }

    ngOnInit(): void {
        // this.fetch();
        /* this.apiData = [
          {url: '../../../../assets/icons/home/sc1.png', id: '1'},
          {url: '../../../../assets/icons/home/sc2.png', id: '2'},
          {url: '../../../../assets/icons/home/sc3.png', id: '3'}
        ] */
    }

    /* fetch() {
        const api = `https://jsonplaceholder.typicode.com/albums/1/photos?_start=0&_limit=${this.limit}`;
        const http$ = this.http.get<PhotosApi>(api);

        http$.subscribe(
            res => this.apiData = res,
            err => throwError(err)
        )
    } */

}
