
import * as rx from 'rxjs'
import { Observable } from 'rxjs';


export class ProfileImage{
 imageSubject = new rx.Subject();

 getValue(): Observable<any> {
    return this.imageSubject.asObservable();
}   

}