import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthActions } from '../../auth/actions/auth.actions';
import { AppState } from '../../services/model/interfaces';
import { ExamLevel } from '../models/examLevel';
import { Gender } from '../models/gender';
import { Language } from '../models/language';
import { StandardType } from '../models/standardType';
import { User } from '../models/user';

@Injectable()
export class SharedService {

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private actions: AuthActions,
    private datePipe: DatePipe
  ) { }



  getGenders(): Observable<Gender[]> {
    return this.http.get(environment.apiEndpoint + '/v1/genderTypes/')
      .pipe(
        map(res => res as Gender[])
      );
  }


  getLanguage(): Observable<Language[]> {
    return this.http.get(environment.apiEndpoint + '/v1/languages/')
      .pipe(
        map(res => res as Language[])
      );
  }

  getStandardType(): Observable<StandardType[]> {
    return this.http.get(environment.apiEndpoint + '/v1/classStandardTypes/')
      .pipe(
        map(res => res as StandardType[])
      );
  }

  getExamLevel(): Observable<ExamLevel[]> {
    return this.http.get(environment.apiEndpoint + '/v1/examlevels/')
      .pipe(
        map(res => res as ExamLevel[])
      );
  }

  updateProfile(user: User, imageUrl: any, backgroundUrl: any): Observable<any> {
    var dateOfBirth = moment.utc(user.dob).valueOf();

    return this.http.put(environment.apiEndpoint + `/v1/users/${user.email}/profile`, {
      'dob': dateOfBirth,
      'email': user.email,
      'examLevelCode': user.examLevelCode,
      'firstName': user.firstName,
      'gender': user.gender,
      'lastName': user.lastName,
      'phone': user.phone,
      'preferredLanguageCode': user.preferredLanguageCode,
      'profileBackgroundImageUrl': backgroundUrl,
      'profileImageUrl': imageUrl,
      'standardTypeCode': user.standardTypeCode
    }).pipe(
      map(res => {

        return res;
      }));

  }


  getStudentHistory(studentId: any, pageNo: any, size: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + `/v1/students/${studentId}/exams/result?page=${pageNo}&size=${size}`).pipe(
      map( res => {
        return res;
      })
    )
  }

  updateProfilePic(email: any, image: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + `/v1/users/${email}/profile/image`, {
      'image': image
    })


  }
}