import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appCheckSame]',
  providers: [{provide: NG_VALIDATORS, useExisting: CheckSameDirective, multi: true}]
})
export class CheckSameDirective implements Validator {

  @Input('appCheckSame') appCheckSame!: AbstractControl;
  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.appCheckSame.value === control.value ? {same: true} : null;
  }
}
