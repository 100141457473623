import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { goal, goalSelectedByStudent } from './model/goalModel';
import { IPageRequestPractice } from './model/page.request.model';
import { SessionEventRequest } from '../modules/feature/post-login/crash-course/crash-course.enum';

@Injectable({
    providedIn: 'root'
})
export class StudentService {

    goalData: any = false

    profileUpdate$: any = new Subject()

    coinCount: any = new Subject()

    constructor(private http: HttpClient) { }

    getScholarshipAssessment(pageRequest: any): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/my-available-scholarship-test`, {
            params: {
                ...pageRequest
            }
        }
        );
    }

    getSelectedGoals(): Observable<goalSelectedByStudent> {
        return this.http.get<goalSelectedByStudent>(`${environment.studentService}/v1/my-goals`);
    }

    setGoals(selectedGoals: goal): Observable<any> {
        return this.http.post<any>(`${environment.studentService}/v1/my-goals`, selectedGoals);
    }

    getProducts(reqParams: any = {}): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-products`, {
            params: {
                ...reqParams
            }
        });
    }

    getProductById(pid:string): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-products/${pid}`);
    }

    getStudentAssessmentResult(payload: any, pid: string, assId: string) {
        return this.http.post<any>(`${environment.studentService}/v1/my-products/${pid}/assessments/${assId}`, payload);
    }

    getStudentAssessmentReport(assId: string) {
        return this.http.get<any>(`${environment.studentService}/v1/my-assessments/${assId}`);
    }

    fetchPracticeFirstQuestion(pid: string, payload: any) {
        return this.http.post<any>(`${environment.studentService}/v1/practices`, payload);
    }

    fetchPracticeNextQuestion(pid: string, practiceId: string, payload: any, action: string = "") {
        console.log(typeof payload)
        return this.http.patch<any>(`${environment.studentService}/v1/practices/${practiceId}?action=${action}`, payload);
    }

    getPracticeDetails(pageRequest: IPageRequestPractice = { size: 1, page: 0 }) {
        return this.http.get<any>(`${environment.studentService}/v1/practices`, {
            params: {
                ...pageRequest
            }
        });
    }

    getPracticeByPracticeId(practiceId: string) {
        return this.http.get<any>(`${environment.studentService}/v1/practices/${practiceId}`);
    }

    getMyProfile(): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-profile`);
    }

    getSummaryReport(reqParams: any = {}) {
        return this.http.get<any>(`${environment.studentService}/v1/my-summary-report`, {
            params: {
                ...reqParams
            }
        });
    }
    setMyProfile(req: any): Observable<any> {
        return this.http.post<any>(`${environment.studentService}/v1/my-profile`, req);
    }
    
    setMyProfilePic(formData: any): Observable<any> {
        return this.http.patch(`${environment.studentService}/v1/my-profile/pic`, formData, {
            reportProgress: true,
            observe: 'events'
        })
    }

    getStudentStreak() {
        return this.http.get<any>(`${environment.studentService}/v1/streak`);
    }
    getStudentCoins() {
        return this.http.get<any>(`${environment.studentService}/v1/coin`);
    }

    setBookMark(req: any): Observable<any> {
        return this.http.post<any>(`${environment.studentService}/v1/my-bookmark`, req);
    }


    getAllBookmark(pagerequest) {
        return this.http.get<any>(`${environment.studentService}/v1/my-bookmark`, {
            params: pagerequest

        });
    }

    unBookmark(qid): Observable<any> {
        return this.http.patch<any>(`${environment.studentService}/v1/my-bookmark/${qid}`, '', {
            params: {
                action: 'undoBookmark'
            }
        });
    }

    getStudentCoinLevel(userName): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/coin/get-level/${userName}`);
    }

    auditTrail(requestBody: any): Observable<any> {
        //let requestBody = { "eventType": eventType }
        return this.http.post<any>(`${environment.studentService}/v1/students/auditrail`, requestBody);
    }
    reportQuestion(reportReasonInfo): Observable<any> {
        return this.http.post<any>(`${environment.studentService}/v1/my-report-question`, reportReasonInfo);
    }

    getAssessment(payload: any): Observable<any> {
        return this.http.post<any>(`${environment.studentService}/v1/my-assessments`, payload);
    }
    getAssessmentResult(payload: any, sessionId: string) {
        return this.http.put<any>(`${environment.studentService}/v1/my-assessments/${sessionId}`, payload);
    }
    submitRating(payload: any): Observable<any> {
        return this.http.post<any>(`${environment.studentService}/v1/ratings`, payload);
    }
    getRating(): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/ratings`);
    }
    getSocialService(): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/social-proof-text`);
    }
    getReferralDetails(): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/referral/referral-details`);
    }

    studentReferral(payload: any): Observable<any> {
        return this.http.post<any>(
            `${environment.studentService}/v1/referral/referral`, payload);
    }

    getSubInsight(subId: any): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-subject-insight/${subId}`);
    }
    getInsightAssessment(sid) {
        return this.http.get<any>(`${environment.studentService}/v1/my-subject-insight/${sid}/assessmentdetails`);
    }
    getSubInsightTimeSpend(sid: string) {
        return this.http.get<any>(`${environment.studentService}/v1/my-subject-insight/time/spend/${sid}`);
    }

    getPaymentInvoice(id: string) {
        return this.http.get<Blob>(`${environment.studentService}/v1/payment/invoice/${id}`, { observe: 'response', responseType: 'blob' as 'json' });
    }

    getPaymentReceipt(id: string) {
        return this.http.get<Blob>(`${environment.studentService}/v1/payment/receipt/${id}`, { observe: 'response', responseType: 'blob' as 'json' });
    }

    postActivity(payload: any): Observable<any> {
        return this.http.post<any>(
            `${environment.studentService}/v1/activity`, payload);
    }

    updateActivity(id: string): Observable<any> {
        return this.http.patch<any>(
            `${environment.studentService}/v1/activity/update/${id}`, {});
    }

    getCoinMessage(username: any): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/coin/msg/${username}`);
    }

    updateCoinMessage(username: string, id: string): Observable<any> {
        return this.http.patch<any>(
            `${environment.studentService}/v1/coin/msg-update/${id}/${username}`, {});
    }

    getStuCoinLogs(reqParams: any): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/coin/logs`, { params: reqParams });
    }
    getStuSubtractCoinLogs(reqParams: any): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/coin/subtract/logs`, { params: reqParams });
    }

    updateExpiredCoin(username: string): Observable<any> {
        return this.http.patch<any>(
            `${environment.studentService}/v1/coin/expired/${username}`, {});
    }

    getLeaderBoardReports(stuId: string, stuScore: number, toplimit: number, id: string): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-assessments/leaderboard/${stuId}/${stuScore}/${toplimit}/${id}`);
    }

    getFreeTestSummary(studentId: string, assessmentType: string): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-assessments/free-test-summary/${studentId}/${assessmentType}`);
    }

    testReportSummary(): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-products/mock/summary?summary=true&action=mock-summary`);
    }

    getStudents(reqParams: any): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/students`, { params: reqParams });
    }

    getFreeTest(pageRequest: any): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/my-available-free-test`, {
            params: {
                ...pageRequest
            }
        }
        );
    }

    postCouponLog(payload: any): Observable<any> {
        return this.http.post<any>(
            `${environment.studentService}/v1/coupon-log`, payload);
    }

    getSubBookmarkChapter(sid: string) {
        return this.http.get<any>(`${environment.studentService}/v1/my-bookmark/subject-chapter/${sid}?action=all-chapter`);
    }


    postVoucherClaim(payload: any): Observable<any> {
        return this.http.post<any>(
            `${environment.studentService}/v1/voucher/voucher-claim`, payload);
    }

    
    getVoucherClaim(id: any): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/voucher/${id}`);
    }

    getVoucherClaimPage(
        pageRequest: any): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/voucher`,
            {
                params: {
                    ...pageRequest
                },
            }
        );
    }

    getAllDiagnostics(diagnosticSearchPageRequest: any = {}): Observable<any> {
        return this.http.get<any>(
            `${environment.studentService}/v1/my-diagnostics`, {
            params: {
                ...diagnosticSearchPageRequest
            }
        });
    }

    createDiagnostic(payload: any): Observable<any> {
        return this.http.post<any>(
            `${environment.studentService}/v1/my-diagnostics`, payload);
    }

    updateDiagnostics(diagnosticId: string, action: string = "", payload: any = {}): Observable<any> {
        return this.http.patch<any>(
            `${environment.studentService}/v1/my-diagnostics/${diagnosticId}?action=${action}`, payload);
    }

    getDianosticsById(diagnosticId: any): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-diagnostics/${diagnosticId}`);
    }

    getDianosticsCount(): Observable<any> {
        return this.http.get<any>(`${environment.studentService}/v1/my-diagnostics-attempt-count`);
    }

    getMockSummaryReport() {
        return this.http.get<any>(`${environment.studentService}/v1/my-summary-report/mock`);
    }

    sessionEvent(payload: SessionEventRequest){
        return this.http.post(`${environment.studentService}/v1/session`,payload);
    }
    purchasedNudge(hours:number) {
        return this.http.get<any>(`${environment.studentService}/v1/my-products/latest/purchased/${hours}?action=nudge`);
    }
}