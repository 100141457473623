<div class="vertical-progress-steps-wrapper">
    <!-- <button class="btn reload-btn" *ngIf="isVisibleResetBtn" (click)="onReset()">
        <img src="../../../../assets/icons/practice/reload-icon.svg" />
    </button> -->
    <ul class="step-progress">
        <li class="step-progress-item" *ngFor="let step of stepList; let idx=index;"
            [ngClass]="{'is-done': step.completed, 'current': step.current, 'is-checked': step.checked}"
            (click)="onTopicToggle(step, idx)">
            <span>
                {{step?.displayNames[0].name}}
                <img *ngIf="step.weak == false" src="../../../../assets/icons/practice/star-icon.svg" />
                <img *ngIf="step.diagnosticWeak == false" src="../../../../assets/icons/practice/star-icon.svg" />
            </span>
            
            <button class="btn reload-btn" *ngIf="idx===0 && showResetBtn" (click)="onReset($event)">
                <img src="../../../../assets/icons/practice/reload-icon.svg" />
            </button>
            
        </li>
    </ul>
</div>