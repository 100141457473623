import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';

const orange = "#F26D0C";
const yellow = "#FDD649";
const green = "#3DD598";

const arcDegree = 180;
const arcWidth = 90;
const arcCenter = 135;
const strokeWidth = 4;
const circleRadius = 8;

const margin = 3;

@Component({
  selector: 'app-mock-meter',
  templateUrl: './mock-meter.component.html',
  styleUrls: ['./mock-meter.component.scss']
})
export class MockMeterComponent {
  @ViewChild('arc1') arc1!: ElementRef;
  @ViewChild('arc2') arc2!: ElementRef;
  @ViewChild('arc3') arc3!: ElementRef;
  @ViewChild('circle') circle!: ElementRef;
  // @ViewChild('score') score!: ElementRef;
  @ViewChild('status') status!: ElementRef;

  @Input('data') data: any;
  @Input('showImproveBtn') showImproveBtn: boolean = true;

  @Output() handleImproveBtn = new EventEmitter<boolean>();

  circleMarker: any;
  // textScore: any;
  textStatus: any;

  min = 0;
  max = 100;
  range = 0;

  scoreText = "";

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['data'].isFirstChange()) {
      this.min = 0;
      this.max = 100;
      this.range = 0;
      this.scoreText = "";

      this.slider(this.data.avgGivenMock);
    }
  }

  ngAfterViewInit(): void {
    this.circleMarker = this.circle.nativeElement;
    this.textStatus = this.status.nativeElement;

    this.slider(this.data.avgGivenMock);
  }

  slider(scoreVal) {
    if(scoreVal < 0) {
      scoreVal = 0;
    }
    this.range = this.max - this.min;

    let score = scoreVal;
    let scoreColor: string = orange;
    let span1 = 32;
    let span2 = 64;
    let span3 = 100;

    span1 = this.filterRange(span1);
    span2 = this.filterRange(span2);
    span3 = this.filterRange(span3);
    this.max = this.filterRange(this.max);
    score = this.filterRange(score);

    let range1S = margin;
    let range1E = span1 - margin;
    let range2S = span1 + margin;
    let range2E = span2 - margin;
    let range3S = span2 + margin;
    let range3E = span3 - margin;

    let arc1 = this.arc1.nativeElement;
    let arc2 = this.arc2.nativeElement;
    let arc3 = this.arc3.nativeElement;

    if (score < span1) {
      scoreColor = orange;
      this.scoreText = "Low";
    } else if (score >= span1 && score < span2) {
      scoreColor = yellow;
      this.scoreText = "Average";
    } else if (score >= span2 && score < span3) {
      scoreColor = green;
      this.scoreText = "High";
    }

    this.moveCircle(arcCenter, 180, arcWidth, score, scoreColor, scoreVal);

    this.alterArc(arc1, orange, range1S, range1E);
    this.alterArc(arc2, yellow, range2S, range2E);
    this.alterArc(arc3, green, range3S, range3E);

  };

  alterArc(arc: any, color: string, start: number, end: number) {
    arc.setAttribute("d", this.describeArc(arcCenter, 180, arcWidth, start, end));
    arc.setAttribute("stroke", color);
    arc.setAttribute("stroke-width", strokeWidth);
  }

  moveCircle(x: number, y: number, radius: number, endAngle: number, color: string, scoreVal: number) {
    let start = this.polarToCartesian(x, y, radius, endAngle);

    if (endAngle >= 0 || endAngle <= arcDegree) {
      this.circleMarker.setAttribute("cx", start.x);
      this.circleMarker.setAttribute("cy", start.y);
      this.circleMarker.setAttribute("r", circleRadius);
      this.circleMarker.setAttribute("fill", color);
      this.circleMarker.setAttribute("stroke-width", strokeWidth);

      // this.textScore.innerHTML = this.scoreText;
      this.textStatus.innerHTML = "<span id='score-val'>" + scoreVal?.toFixed(2) + "%</span>" ;
      //+ " <span style='font-size: 10px;font-weight: 100;'>/ 100</span>"
    } else {
      this.circleMarker.style.display = "none";
    }
  }

  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {

    let start = this.polarToCartesian(x, y, radius, endAngle);
    let end = this.polarToCartesian(x, y, radius, startAngle);

    let largeArcFlag = endAngle - startAngle <= arcDegree ? "0" : "1";

    let d = [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
  }

  polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    let angleInRadians = (angleInDegrees - arcDegree) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  filterRange(r: number) {
    r = r - this.min;
    r = Math.round(r / this.range * arcDegree);
    return r;
  }

  doMockImprove() {
    this.handleImproveBtn.emit(true);
  }
}
