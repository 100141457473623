import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[appPassVal]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidator, multi: true }]
})
export class PasswordValidator implements Validator {

    //   @Input('appCompare') appCompare: AbstractControl;
    constructor() { }

    validate(control: AbstractControl): { [key: string]: any } | null {

        const validated: any = {};

        if (control.value.length < 8) {
            validated['length'] = '*Password must be at least 8 characters';
        } else if (control.value.length > 32) {
            validated['length'] = '*Password must be less than 32 characters';
        }

        const regCapChar = new RegExp('[A-Z]');
        if (!String(control.value).match(regCapChar)) {
            validated['capChar'] = '*Atleast one capital character';
        }

        const regSmlChar = new RegExp('[a-z]');
        if (!String(control.value).match(regSmlChar)) {
            validated['smlChar'] = '*Atleast one small character';
        }

        const regNum = new RegExp('[0-9]');
        if (!String(control.value).match(regNum)) {
            validated['num'] = '*Atleast one Numeric';
        }

        const regSpecialChr = new RegExp('[`|~|!|@|#|$|%|\^|&|*|=|+|/|\\|?|>|<|,|(|)|{|}|[|]|\'|-|:|;|\"]');
        if (!String(control.value).match(regSpecialChr)) {
            validated['specialChr'] = '*Atleast one Special character';
        }

        return validated;
    }
}
