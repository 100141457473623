<span mat-dialog-actions align="end" style="z-index: 4000;padding-bottom: 5px;display: flex;justify-content: flex-end;" (click)="onClose()">
    <img src="../../../../assets/close-icon.png" alt="">
</span>
<div class="max-attempt-container">
    <!-- <div>
        <button type="button" class="btn close-btn" (click)="onClose()" aria-label="Close">
            <img src="../../../../assets/icons/practice/close-btn.svg" />
        </button>
    </div> -->
    <div class="header">
        <span class="header-content">{{maxCount}}/{{maxCount}} questions attempted</span>
    </div>
    
    <div mat-dialog-content>
        <div class="insights-data">
            <span>You have attempted {{maxCount}} questions which is the maximum number of questions to be attempted, in case you want to attempt any other question <span style="font-weight: bold;">please clear your response and try again</span></span>
        </div>

        
    </div>
    <div class="footer" style="padding-top: 10px;">
            
        <div class="col-12 ps-lg-1" style="display: flex;justify-content: center;">
            <button type="button" class="btn fill-btn" (click)="onClose()" style="width: 90%;">Okay,got it!</button>
        </div>
    </div>
</div>
