
<div ngbAccordion *ngFor="let item of accordianInputData;let i=index">
    <div ngbAccordionItem  class="accordion-item" [collapsed]="i > 0">
        <p ngbAccordionHeader>
            <button ngbAccordionButton><img *ngIf="item.header.headerIconUrl" class="me-2" [src]="item.header.headerIconUrl" alt="">{{item.header.headerText}}</button>
        </p>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div  *ngIf="item.content.type == 'text'" [innerHTML]="item.content.details"></div>
                    <div  *ngIf="item.content.type == 'image'"><img [src]="item.content.imageUrl" class="image-accordian" alt=""></div>
                    <div *ngIf="item.content.type == 'video'"><iframe width="100%" height="400" class="video-border" [src]="item.content.videoUrl | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
