// my-loader.component.ts
import { Component } from '@angular/core';
import { GlobalSpinnerService } from './global-spinner.service';

@Component({
    selector: 'app-global-spinner',
    templateUrl: './global-spinner.component.html',
    styleUrls: ['./global-spinner.component.scss']
})
export class GlobalSpinnerComponent {
    loading: boolean = false;;

    constructor(private loaderService: GlobalSpinnerService) {
        this.loaderService.isLoading.subscribe((v) => {
            window.scrollTo(0,0);
            this.loading = v;
            const body = document.getElementsByTagName('body')[0];
            this.loading ?  body.classList.add('overflow-hidden') :
            body.classList.remove('overflow-hidden');
        });

    }
}
