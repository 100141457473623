<div class="refer-container">
    <div>
        <button type="button" class="btn close-btn" (click)="onClose()" aria-label="Close">
            <img src="../../../../assets/icons/practice/close-btn.svg" />
        </button>
    </div>
    <div class="header">
        <span class="header-content">Get more out of your friends</span>
    </div>
    
    <div mat-dialog-content>
        <div class="insights-data">
            
            
            <div class="row-data"><span>Coins earned</span><span>50</span></div> 
            <div class="row-data"><span>Referrals</span><span>2</span></div>   
            
        </div>

        <div class="col-12 coupon-input-conatiner mb-5">
            <div class="lms-input-btn">
                <input class="form-control" type="text" style="color: white;" readonly [(ngModel)]="cCode" />
                <button class="btn ef-lms-btn" (click)="copyCode()">Copy Code</button>
            </div>
        </div>
    </div>
    <div class="footer">
            
        <div class="col-12 ps-lg-1" style="display: flex;justify-content: center;">
            <button type="button" class="btn fill-btn" (click)="onClose()" style="width: 90%;">Invite</button>
        </div>
    </div>
</div>
