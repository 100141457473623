import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ProductService } from 'src/app/services/product.service';
import { StudentService } from 'src/app/services/student.service';

@Component({
    selector: 'app-practice-payment-modal',
    templateUrl: './practice-payment-modal.component.html',
    styleUrls: ['./practice-payment-modal.component.scss']
})
export class PracticePaymentModalComponent implements OnInit {

    @Input() modalReference: any
    @Input() public practiceType:string;

    selectedPlan: any;
    planList: any = []
    constructor(
        private router: Router,
        public activeModal: NgbActiveModal,
        private productService: ProductService,
        private studentService: StudentService
    ) { }

    ngOnInit(): void {
        let storageData: any = localStorage.getItem('selectedGoals')
        console.log(typeof JSON.parse(storageData))
        let productData = JSON.parse(storageData)
        let categoryId = productData.selectedGoals[0].categoryId;
        let examId = productData.selectedGoals[0].exams[0].examId;
        if(this.practiceType == '' || this.practiceType == undefined) {
            this.practiceType = 'PRACTICE';
        }


        this.productService.getProducts({
            keyFeature: this.practiceType,
            // mode:'LMS',
            catagoryCode: categoryId, //local storage
            examId: examId, //local storage
            state: "PUBLISHED",
            sort: ["rowCreatedDate dsc"]
        }).subscribe(response => {
            //console.log(response);
            this.planList = response.content;
            this.selectedPlan = response.content[0];
            this.studentService.getProducts().subscribe(resp => {
                let arr: any[] = resp.filter((r: any) => r.prop && r.prop.product.keyFeature === this.practiceType);
                // console.log(arr);
                if (arr.length != 0) {
                    let arr1 = response.content.filter((r: any) => r !== arr[0].prop.product && r.studentTenure > arr[0].prop.product.studentTenure);
                    this.planList = arr1;
                    this.selectedPlan = this.planList[0];
                }
                // else {
                //     this.subStatus = false;
                //     this.getAllPracticeProducts();
                // }

            });
        })
        // this.selectedPlan = this.planList[0]
    }


    makePayment() {
        this.router.navigate(['post-login/payment/'+this.selectedPlan.id], { queryParams: { pId: this.selectedPlan.id } });
        this.modalReference.close()
    }

    onSelectPlan(plan) {
        this.selectedPlan = plan
    }
}
