<div class="row">
    <div class="col-12">
        <!-- Sign up form -->
    <div class="col-md-6 offset-md-3 col-sm-12">
        
        <div class="text-center">
            <h2>CCAvenue Form</h2>
        </div>
        <form #myFormPost name="myFormPost" class="horizontol-form" method="POST" action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
            <div class="mb-3 row">
                <label for="access_code" class="col-4 col-form-label">access_code</label>
                <div class="col-8">
                    <input type="hidden"  ngModel="{{accessCode}}" name="access_code" placeholder="access_code" autocomplete="off"
                        class="form-control" required>
                </div>
            </div>
            <div class="mb-3 row">
                <label for="encRequest" class="col-4 col-form-label">encRequest</label>
                <div class="col-8">
                    <input type="hidden" id="encRequest" name="encRequest" ngModel="{{encRequest}}" placeholder="encRequest"
                        class="form-control">
                </div>
            </div>
            <!-- <div class="mb-3 row">
                <div class="col-md-4 offset-md-4 col-sm-12 d-grid">
                    <div class="text-center">
                    <button class="btn btn-primary" type="button" (click)="onPost()" >Proceed to payment</button> 
                    </div>
                </div>
            </div> -->
        </form>
    </div>
    </div>
</div>