import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../../core/models/product';

@Component({
  selector: 'app-inner-product',
  templateUrl: './inner-product.component.html',
  styleUrls: ['./inner-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerIproduct implements OnInit {

  @Input() product: Product = {} as Product;
  isDIsabled: boolean = true;
  constructor() {
  }

  ngOnInit() {
  }

  getProductImageUrl() {
    //const url = `/spree/products/${this.product.master.images[0].id}/product/${this.product?.master?.images[0]?.attachment_file_name}`
    //return environment.apiEndpoint + url;
    return "";
  }

  getAvgRating(product: Product){
    return product.avg_rating;
  }
}
