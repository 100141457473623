<div class="referEarnBottomSheetWrapper">  
        <span class="closeBtnaction" mat-dialog-actions align="end" style="z-index: 4000;" (click)="onBottomSheetclose()">
            <img src="../../../../../assets/close-icon.png" width="15" alt="">
        </span> 
        
    <div class="container-fluid refer-container refer-container-mobile">
            <div class="row refer-dv">
                <div class="col-12 header d-flex flex-row align-items-center">
                    <span>Get more out of your friends</span>
                </div>
                <div class="coins-dv" >
                <div class="col-12 navigate-Card d-flex flex-row  align-items-center justify-content-between">
                    <span>
                    <img src="../../../../../../assets/icons/header/coin.svg" alt="" class="coinandreferraliconstyle" style="margin-right: 10px;width: 14px;height: 14px;">
                    <span style="opacity: 0.7;">Coins earned </span></span>
                    <span class="referailCodeopt" >{{ dynamicData.coinEarned }}</span>
                </div>
        
        
                <div class="col-12 navigate-Card d-flex flex-row align-items-center referralCodeTop  justify-content-between">
                    <span>
                    <img src="../../../../../../assets/icons/header/referral.svg" alt="" class="coinandreferraliconstyle" style="margin-right: 10px;width: 14px;height: 14px;">
                    <span style="opacity: 0.7;">Referrals</span>
                    </span>
                    <span class="referailCodeopt" >{{ dynamicData.referrals}}</span>
                </div>
                </div>
        
        <!--    
                <div class="col-12 navigate-Card d-flex flex-row align-items-center justify-content-between px-4 mt-4">
                    <span #referralCodeSpan>{{ dynamicData.referralCode }}</span>
                <button class="btn d-block d-lg" (click)="copyToClipboard()">COPY CODE</button>
                </div>
              <div *ngIf="isCodeCopied" class="copy-success-message">Code copied successfully</div> -->
        
        
            <div class="col-12 coupon-card d-flex flex-row align-items-center justify-content-between">
                <span #referralCodeSpan style="font-weight: 500;font-size: 14px;">{{ dynamicData.referralCode }}</span>
                <button class="btn d-block d-lg" (click)="copyToClipboard()">COPY CODE</button>
            </div>
            <div *ngIf="isCodeCopied" class="copy-success-message"><strong>Code copied successfully</strong></div>
               
        
            
                <!-- <button class="btnn d-block d-lg mt-3">INVITE</button> -->
        
        
               
            </div>
        </div>
        
    </div>