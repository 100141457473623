<div class="card">
  <div class="card-body">
      <!-- <img class="company-logo" [src]="tm.logo"> -->
    <p class="text">{{reviewCard.text}}</p>
    <div class="user-detail">
      <span class="toolbar-avatar md user-photo"><img src={{reviewCard.image}} style="width: 50px" ></span>
      <span class="user-title">{{reviewCard.title}}</span>
      <span class="user-subtitle text-muted">{{reviewCard.subtitle}}</span>
    </div>
    </div>
</div>

