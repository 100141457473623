import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { goal } from './model/goalModel';

@Injectable({
    providedIn: 'root'
})
export class MasterDataService {

    constructor(private http: HttpClient) { }

    getAllGoals(): Observable<goal[]> {
        return this.http.get<goal[]>(`${environment.masterDataService}/v1/goals`)
    }

    getSubjectBySubjectId(subjectId: string, queryParam: any = {}) {
        return this.http.get<any>(`${environment.masterDataService}/v1/subjects/${subjectId}`, {
            params: { ...queryParam }
        });
    }

    getChapterBySubject(subjectId: string) {
        return this.http.get<goal[]>(`${environment.masterDataService}/v1/subjects/${subjectId}/chapters`);
    }

    getTopicsByChapter(subjectId: string, unitId: string, chapterId: string,mode:string='practice') {
        return this.http.get<goal[]>(`${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}?module=${mode}`);
    }

    getSubjectsByExamId(examId: string) {
        return this.http.get<any>(`${environment.masterDataService}/v1/exams/${examId}`);
    }

    getAllStates() {
        return this.http.get<any>(`${environment.masterDataService}/v1/states`);
    }

    getStateById(stateId:string) {
        return this.http.get<any>(`${environment.masterDataService}/v1/states/${stateId}`);
    }
}
