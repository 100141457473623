import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProducts(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.productService}/v1/products`,
      {
        params: params,
      }
    );
  }
  getProduct(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.productService}/v1/products/${id}`);
  }

  attachCouponToProduct(product: any, coupon: any = null) {
    product.coupon = coupon
    const couponDetails: any = {}
    //if (coupon && coupon.couponType === 'FLAT_DISCOUNT') {
    let productPrice = product.totalPriceAfterDiscount || product.totalPrice
    product.isApplyCoupon = true
    product.offeredPrice = (productPrice - this.calculateDiscount(coupon,productPrice));
    product.discountValue = this.calculateDiscount(coupon,productPrice);
    //}
    return product
  }

  calculateDiscount(coupon:any,prodPrice:number) {
    let disAmt = 0;
    if(coupon.discountValue.discountType === 'PERCENTAGE') {
      disAmt = ((prodPrice * coupon.discountValue.discountValue) / 100);
      if(disAmt > coupon.discountValue.maximumDiscountedAmount) {
        disAmt = coupon.discountValue.maximumDiscountedAmount;
      }
    } else {
      disAmt = coupon.discountValue.discountValue;
    }
    return disAmt;
  }

  dettachCouponToProduct(product: any) {
    delete product.coupon
    const couponDetails: any = {}
    product.isApplyCoupon = false
    delete product.offeredPrice
    delete product.discountValue
    return product
  }

  downloadProductSyllabus(productId: string) {
    return this.http.get<Blob>(`${environment.productService}/v1/products/${productId}/syllabus`, { observe: 'response', responseType: 'blob' as 'json' });
}

}
