import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-incremental-counter',
    templateUrl: './incremental-counter.component.html',
    styleUrls: ['./incremental-counter.component.scss']
})
export class IncrementalCounterComponent implements OnChanges {

    @Input() resetTimer: boolean = false;
    @Input() pauseTimerEv: boolean = false;
    @Input() resumeTimerEv: boolean = false;
    callTime = 0;
    public text;
    interval;
    constructor() {
        // clearInterval(this.interval);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['resetTimer'] && changes['resetTimer'].currentValue) {
            this.stopTimer();
            this.startTimer();
        }
        if (changes['pauseTimerEv'] && changes['pauseTimerEv'].currentValue) {
            if (this.interval) {
                clearInterval(this.interval);
            }
        }
        if (changes['resumeTimerEv'] && changes['resumeTimerEv'].currentValue) {
            this.startTimer();
        }
    }

    startTimer() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            this.updTimer();
        }, 1000);
    }

    updTimer() {
        let day = '00';
        this.callTime += 1000;
        if (this.callTime >= 86400000) {
            let d = ~~(this.callTime / 86400000); //bitwise operator to remove decimals
            day = d.toString();
        }
        // console.log('', this.callTime, day);
        if (day != '00') {
            this.text =
                day + ':' + new Date(this.callTime).toUTCString().split(/ /)[4];
        } else {
            this.text = new Date(this.callTime).toUTCString().split(/ /)[4];
        }
    }

    stopTimer() {
        this.callTime = 0;
        this.text = '00:00:00';
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
