import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[appDOBVal]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DOBValidator, multi: true }]
})


export class DOBValidator implements Validator {

    //   @Input('appCompare') appCompare: AbstractControl;
    constructor() { }

    validate(control: AbstractControl): { [key: string]: any } | null {

        const validated: any = {};

        if (control.value && control.value.month && control.value.day) {
            const valueStr = `${control.value.year}-` +
                `${control.value.month.toString().length === 1 ? '0' + control.value.month.toString() : control.value.month.toString()}`
                + `-${control.value.day.toString().length === 1 ? '0' + control.value.day.toString() : control.value.day.toString()}`;

            const regDate = new RegExp('^[0-9]{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$');
            if (!String(valueStr).match(regDate)) {
                validated['date'] = '*date is invalid';
            }
        }


        return validated;
    }
}
